import React, { useEffect, useState } from "react";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import Dropdown from "../../../common/components/monthDropdown";
import LevelBar from "../../../common/components/LevelBar";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop
} from "recharts";
import AdminGrid from "../../../common/components/AdminGrid";
import AdminFundingBar from "../../../common/components/AdminFundingBar";
import { Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessAnalyticsDataAction } from '../../../redux/actions/dashboardAction';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import { axiosGet } from "../../../redux/axios";
import AdminFundingBar1 from "../../../common/components/AdminFundingBar1";


const data = [
  { name: 'Jan', uv: 800 },
  { name: 'Feb', uv: 900 },
  { name: 'Mar', uv: 1000 },
  { name: 'Apr', uv: 1280 },
  { name: 'May', uv: 1390 },
  { name: 'Jun', uv: 1590 },
  { name: 'Jul', uv: 2090 },
  { name: 'Aug', uv: 2290 },
  { name: 'Sep', uv: 2490 },
  { name: 'Oct', uv: 2490 },
  { name: 'Nov', uv: 2590 },
  { name: 'Dec', uv: 2790 },
];

const BusinessAnalytics = () => {
  const [businessAnalyticsChartData, setBusinessAnalyticsChartData] = useState([]);
  const [funcdingTarget, setFundingTarget] = useState(0);
  const [achievedTarget, setAchievedTarget] = useState(0);
  const [achievedPercentage, setAchievedPercentage] = useState(0);
  const [underGoal, setUnderGoal] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'));
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
  const [topCompaniesData, setTopCompaniesData] = useState([])
  const [topAgentData, setTopAgentData] = useState([])
  const { userId } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTop5Companies();
  }, [])

  useEffect(() => {
    getTop5Agent();
  })

  const dispatch = useDispatch();

  const { loading: businessAnalyticsDataLoading, businessAnalyticsData } = useSelector((state) => state.getBusinessAnalyticsData);

  // console.log(businessAnalyticsChartData, 'businessAnalyticsChartData');


  const getTop5Companies = async () => {
    try {
      console.log("My userID :: ", userId)
      const response = await axiosGet(`dashboard/getTopLeads?user_id=${userId}`);
      const data = response.data.topLeads;


      // Format data if necessary to match { name, uv } structure
      const formattedData = data.map(company => ({
        name: company.company_name, // Ensure 'name' is the company name field
        amount: company.total_funding_amount || 0, // Ensure 'uv' is the funding field
      }));

      setTopCompaniesData(formattedData);
      console.log("My data Lead::", formattedData)
    } catch (error) {
      console.error('Error fetching top companies:', error);
    }
  };

  
  const getTop5Agent = async () => {
    try {
      const response = await axiosGet(`dashboard/getTopAgents`);
      const data = response.data.topUsers;

      // Format data if necessary to match { name, uv } structure
      const formattedData = data.map(company => ({
        name: company.fullName, // Ensure 'name' is the company name field
        Submission: company.dealCount || 0, // Ensure 'uv' is the funding field
      }));

      setTopAgentData(formattedData);
      // console.log("My Agent data ::", formattedData)
    } catch (error) {
      console.error('Error fetching top agent:', error);
    }
  };

  useEffect(() => {
    if (businessAnalyticsData && topCompaniesData.length > 0 && topAgentData.length > 0) {
      // Once all data is fetched, we can set loading to false
      
    }
    setLoading(false);
  }, [businessAnalyticsData, topCompaniesData, topAgentData]);

  const getAllDatesInMonth = (year, month) => {
    const date = new Date(year, month - 1, 1);
    const dates = [];
    while (date.getMonth() === month - 1) {
      dates.push(new Date(date).toISOString().split('T')[0]); // Add date in 'YYYY-MM-DD' format
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  useEffect(() => {
    dispatch(getBusinessAnalyticsDataAction(selectedMonth, selectedYear));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const allDatesInMonth = getAllDatesInMonth(selectedYear, moment().month(selectedMonth).format('M'));
    const today = new Date().toISOString().split('T')[0];
    const validDatesTillToday = allDatesInMonth.filter(date => date <= today);

    if (businessAnalyticsData?.data) {
      const groupedData = businessAnalyticsData?.data?.reduce((acc, current) => {
        const { date, funding_amounts } = current;

        const existingDate = acc?.find(item => item.date === date);
        if (existingDate) {
          existingDate.funding_amounts += funding_amounts;
        } else {
          acc.push({ date, funding_amounts });
        }
        return acc;
      }, []);

      const finalData = validDatesTillToday?.map(date => {
        const existing = groupedData.find(item => item.date === date);
        return existing ? existing : { date, funding_amounts: 0 };
      });

      const formatedData = finalData?.map((item) => ({
        name: moment(item.date).format("DD MMM"),
        amount: item.funding_amounts
      }))
      setBusinessAnalyticsChartData(formatedData);
    } else {
      const finalData = validDatesTillToday?.map(date => {
        return { date, funding_amounts: 0 };
      });

      const formatedData = finalData?.map((item) => ({
        name: moment(item.date).format("DD MMM"),
        amount: item.funding_amounts
      }))
      setBusinessAnalyticsChartData(formatedData);
    }
    setFundingTarget(businessAnalyticsData?.funding_target);
    setAchievedTarget(businessAnalyticsData?.achieved_target);
    if (businessAnalyticsData?.funding_target && businessAnalyticsData?.achieved_target) {
      const achievedPercentage = (businessAnalyticsData?.achieved_target * 100) / businessAnalyticsData?.funding_target;
      setAchievedPercentage(achievedPercentage);
    }
    if (businessAnalyticsData?.achieved_target > businessAnalyticsData?.funding_target) {
      setUnderGoal("0");
    } else {
      // setUnderGoal(businessAnalyticsData?.funding_target - businessAnalyticsData?.achieved_target || 0);
      setUnderGoal(businessAnalyticsData?.funding_target - businessAnalyticsData?.achieved_target);
    }
  }, [businessAnalyticsData]);

  const leadType = [
    // { name: 'BV', uv: 4000, amt: 2400 },
    // { name: 'RLT', uv: 3000, amt: 2210 },
    // { name: 'Channel', uv: 2000, amt: 2290 },
    // { name: 'CPW', uv: 2780, amt: 2000 },
    // { name: 'Fire', uv: 1890, amt: 2181 },
    topCompaniesData.map(leadData => ({
      name: leadData.company_name,
      uv: leadData.total_funding_amount
    }))
  ];

  const agent = [
    // { name: 'Kevin', uv: 4000, pv: 2400, amt: 2400 },
    // { name: 'David', uv: 3000, pv: 1398, amt: 2210 },
    // { name: 'John', uv: 2000, pv: 9800, amt: 2290 },
    // { name: 'Ryan', uv: 2780, pv: 3908, amt: 2000 },
    // { name: 'Daniel', uv: 1890, pv: 4800, amt: 2181 },
  ];

  const monthsOptions = [
    {
      label: "January",
      value: "January"
    },
    {
      label: "February",
      value: "February"
    },
    {
      label: "March",
      value: "March"
    },
    {
      label: "April",
      value: "April"
    },
    {
      label: "May",
      value: "May"
    },
    {
      label: "June",
      value: "June"
    },
    {
      label: "July",
      value: "July"
    },
    {
      label: "August",
      value: "August"
    },
    {
      label: "September",
      value: "September"
    },
    {
      label: "October",
      value: "October"
    },
    {
      label: "November",
      value: "November"
    },
    {
      label: "December",
      value: "December"
    },
  ]

  const yearsOptions = [
    {
      label: "2021",
      value: "2021"
    },
    {
      label: "2022",
      value: "2022"
    },
    {
      label: "2023",
      value: "2023"
    },
    {
      label: "2024",
      value: "2024"
    },
    {
      label: "2025",
      value: "2025"
    },
    {
      label: "2026",
      value: "2026"
    },
    {
      label: "2027",
      value: "2027"
    },
    {
      label: "2028",
      value: "2028"
    },
    {
      label: "2029",
      value: "2029"
    },
    {
      label: "2030",
      value: "2030"
    },
  ];

  const handleSelectYear = (e) => {
    console.log(e, "dhcvsh")
    setSelectedYear(e);
  }

  const handleSelectMonth = (e) => {
    console.log(e, "dhcvsh_____")
    setSelectedMonth(e);
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value || "-")
  }

  return (
    <div className="flex h-auto">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-auto flex flex-col max-w-[90%]">
        <GoodMorningBar userName="Manish" />
        {/* <Spin spinning = {businessAnalyticsDataLoading}> */}
          {businessAnalyticsDataLoading || loading ?
            <div className='w-full h-screen pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
        <div>

             <div className="w-full">
          <div className="bg-white py-6 px-10 w-full mt-7 rounded-xl">
            <div className="flex space-x-4 mb-10">
              <Select
                value={selectedMonth}
                options={monthsOptions}
                onChange={(e) => handleSelectMonth(e)}>
              </Select>
              <Select
                value={selectedYear}
                options={yearsOptions}
                onChange={(e) => handleSelectYear(e)}>
              </Select>
            </div>
            <div className="flex justify-between mb-4 mt-5">
              <div className="font-bold text-lender-green-text text-md text-ellipsis">
                Funding target: <span className="font-normal ml-3">{formatCurrency(funcdingTarget)}</span>
              </div>
              <div className="font-bold text-lender-green-text text-md text-ellipsis">
                Achieved   <span className="font-normal ml-4">{formatCurrency(achievedTarget)}</span>
              </div>
            </div>
            <div className="mb-4">
              <LevelBar achievedPercentage={achievedPercentage} />
            </div>
            <div className="flex justify-between mb-3">
              <div className="font-bold text-lender-green-text text-md">
                Achieved %:  <span className="font-normal ml-4">{achievedPercentage.toFixed(2) ?? "-"}%</span>
              </div>
              <div className="font-bold text-red-600 text-md">
                Under Goal   <span className="font-normal ml-4 text-lender-green-text">{formatCurrency(underGoal)}</span>
              </div>
            </div>
            <div className="mb-4">

              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  width={730}
                  height={250}
                  data={businessAnalyticsChartData}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
                  >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#00A762" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#00A762" stopOpacity={0} />
                    </linearGradient>

                  </defs>
                  <XAxis dataKey="name" />
                  {/* <CartesianGrid strokeDasharray="1 1" /> */}
                  <Tooltip />
                  <Area type="monotone" dataKey="amount" stroke="green" fillOpacity={1} fill="url(#colorUv)" />

                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        
        </div>
            <div className="flex mt-7">
              <AdminGrid />
              {loading ?
               <div className='w-full pt-16 flex justify-center items-center' >
               <Spin />
             </div>  
             :
                <div style={{width:'35vw'}}>
                  <AdminFundingBar data={topCompaniesData} loading={loading} heading={'Top 5 lead type by funding'} />
                  <AdminFundingBar1 data={topAgentData} loading={loading} heading={'Top 5 agents for submissions'} />
                </div>

              }
            </div>
      {/* </Spin> */}
        </div>
                }
      </main>
    </div>
  );
};

export default BusinessAnalytics;
