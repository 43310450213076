export const GET_USER_DEALS_REQUEST = 'GET_USER_DEALS_REQUEST';
export const GET_USER_DEALS_SUCCESS = 'GET_USER_DEALS_SUCCESS';
export const GET_USER_DEALS_FAIL = 'GET_USER_DEALS_FAIL';

export const GET_OLD_DEALS_REQUEST = 'GET_OLD_DEALS_REQUEST';
export const GET_OLD_DEALS_SUCCESS = 'GET_OLD_DEALS_SUCCESS';
export const GET_OLD_DEALS_FAIL = 'GET_OLD_DEALS_FAIL';

export const GET_OLD_LENDER_BY_DEAL_ID_REQUEST = 'GET_OLD_LENDER_BY_DEAL_ID_REQUEST';
export const GET_OLD_LENDER_BY_DEAL_ID_SUCCESS = 'GET_OLD_LENDER_BY_DEAL_ID_SUCCESS';
export const GET_OLD_LENDER_BY_DEAL_ID_FAIL = 'GET_OLD_LENDER_BY_DEAL_ID_FAIL';

export const SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST = 'SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST';
export const SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS = 'SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS';
export const SEND_DEAL_TO_SUBMISSION_BOARD_FAIL = 'SEND_DEAL_TO_SUBMISSION_BOARD_FAIL';

export const APPROVE_DEAL_REQUEST = 'APPROVE_DEAL_REQUEST';
export const APPROVE_DEAL_SUCCESS = 'APPROVE_DEAL_SUCCESS';
export const APPROVE_DEAL_FAIL = 'APPROVE_DEAL_FAIL';

export const DECLINE_DEAL_REQUEST = 'DECLINE_DEAL_REQUEST';
export const DECLINE_DEAL_SUCCESS = 'DECLINE_DEAL_SUCCESS';
export const DECLINE_DEAL_FAIL = 'DECLINE_DEAL_FAIL';
