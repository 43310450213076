import { Outlet , Navigate } from "react-router-dom";
import { userIdAtom } from "../atom/authuserData/userdata";
import { useRecoilValue } from "recoil";

const ProtectedRoutes  = ({ children }) => {

    const sessionData = JSON.parse(localStorage.getItem('sessionData'));
    const isAuthenticated = sessionData && new Date().getTime() < sessionData.expiry;

    const userId = useRecoilValue(userIdAtom);
    return isAuthenticated  ? <Outlet/> : <Navigate to="/"/>
}

export default ProtectedRoutes;