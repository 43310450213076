import { Input, Modal, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { MdUploadFile } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { userIdAtom } from '../../../../../atom/authuserData/userdata'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import { uploadFileAction } from '../../../../../redux/actions/leadsActions'
import animatedOk from "../../../../../data/ok.gif";

const { Dragger } = Upload;

const UploadFileModal = ({ getAllFiles, deal, visible, onCancel }) => {
  const [file, setFile] = useState(null)
  const [renamedFileName, setRenamedFileName] = useState('')
  const [viewStatistics, setViewStatistics] = useState(false)
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  const { loading: uploadLoading } = useSelector(state => state.uploadFile);

  useEffect(() => {
    // getAllFiles()
  }, [deal])

  const uploadProps = {
    onRemove: () => {
      setFile(null)
    },
    beforeUpload: (inputFile) => {
      if (viewStatistics) setViewStatistics(false)
      const isPdfFormat = /\.pdf$/.test(inputFile.name)
      // && /\.sheet$/.test(inputFile.type)
      if (isPdfFormat) setFile(inputFile)
      else void console.log('Please upload in .pdf file format')
      return false
    },
    maxCount: 1,
    fileList: file === null ? [] : [file],
    accept: ".pdf"
  }

  const handleUpload = async (e) => {
    // e.stopPropagation()
    if (file) {
      let fileName = renamedFileName !== "" ? renamedFileName : file?.name;
      const formData = new FormData();
      formData.append('lead_id', deal?._id);
      formData.append('title', fileName);
      formData.append('files', file);

      const result = await dispatch(uploadFileAction(userId, formData));
      if (result?.status === 1) {
        setFile(null);
        toast.success(result?.message)
        getAllFiles()
        onCancel()
      } else {
        toast.error(result?.message)
      }
    }
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={600}
      centered
      footer={null}
    >
      <div className="w-full mt-5">
        <Dragger {...uploadProps}>
          <span className="text-primary text-6xl flex justify-center">
            <MdUploadFile size={40} color='#00A762' />
          </span>
          <p className="ant-upload-text font-semibold py-3">
            Add another document
          </p>
        </Dragger>
        {file ?
          <div className='flex justify-between items-center w-full'>
            <Input
              placeholder='Rename the file'
              className='w-[80%]'
              suffix=".pdf"
              onChange={e => setRenamedFileName(e.target.value)}
            />
            {uploadLoading ?
              <div className='w-10 h-10 rounded-full overflow-hidden' >
                <img src={animatedOk} width="40px" height="40px" alt='' className='bg-transparent rounded-full scale-[1.2]' />
              </div> :
              <div
                className='w-10 h-10 flex justify-center items-center cursor-pointer'
                onClick={() => handleUpload()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                  <path fill="#c8e6c9" d="M44,24c0,11-9,20-20,20S4,35,4,24S13,4,24,4S44,13,44,24z"></path><polyline fill="none" stroke="#4caf50" stroke-miterlimit="10" stroke-width="4" points="14,24 21,31 36,16"></polyline>
                </svg>
              </div>
            }
          </div> : null
        }
      </div>
    </Modal >
  )
}

export default UploadFileModal