import moment from 'moment';
import { axiosGet, axiosPost } from '../axios';
import {
  CREATE_NEW_TASK_FAIL,
  CREATE_NEW_TASK_REQUEST,
  CREATE_NEW_TASK_SUCCESS,
  GET_ADMIN_CARD_SB_DATA_FAIL,
  GET_ADMIN_CARD_SB_DATA_REQUEST,
  GET_ADMIN_CARD_SB_DATA_SUCCESS,
  GET_ALL_TASKS_FAIL,
  GET_ALL_TASKS_REQUEST,
  GET_ALL_TASKS_SUCCESS,
  GET_BUSINESS_ANALYTICS_DATA_FAIL,
  GET_BUSINESS_ANALYTICS_DATA_REQUEST,
  GET_BUSINESS_ANALYTICS_DATA_SUCCESS,
  GET_CHART_DATA_FAIL,
  GET_CHART_DATA_REQUEST,
  GET_CHART_DATA_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_ADMIN_SUBMISSION_DATA_FAIL,
  GET_ADMIN_SUBMISSION_DATA_REQUEST,
  GET_ADMIN_SUBMISSION_DATA_SUCCESS,
  SET_FUNDING_TARGET_FAIL,
  SET_FUNDING_TARGET_REQUEST,
  SET_FUNDING_TARGET_SUCCESS,
} from '../types/dashboardTypes';
import APIUrl from '../../atom/APIUrl';
import { useSelector } from 'react-redux';

export const getAllTasksAction = (userId, date) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TASKS_REQUEST });

    const { data } = await axiosPost(`tasks/getTasks?user_id=${userId}`, {
      date: moment(date).format("DD-MM-YYYY")
    });

    dispatch({ type: GET_ALL_TASKS_SUCCESS, payload: data?.tasks });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ALL_TASKS_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const createNewTaskAction = (userId, taskData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_NEW_TASK_REQUEST });

    const { data } = await axiosPost(`tasks/createTask?user_id=${userId}`, taskData);

    dispatch({ type: CREATE_NEW_TASK_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: CREATE_NEW_TASK_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const fetchCompanyDataAction = (userId , role) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMPANIES_REQUEST });

    // const { data } = await axiosGet(`deals/${ role == 'agents' ?  'getSubmissionBoardDeals' :  'getUnclaimedDeals'}?user_id=${userId}`);
    const { data } = await axiosGet(`deals/${ role == 'agents' ?  'getSubmissionBoardDeals' :  'getUnclaimedDeals'}?user_id=${userId}`);
    
    dispatch({ type: GET_COMPANIES_SUCCESS, payload: role == 'agents' ? data?.deals  :  data?.data });
  } catch (error) {
    console.error('Error fetching data:', error);
    dispatch({
      type: GET_COMPANIES_FAIL,
      payload: error.message || 'Failed to fetch data',
    });
  }
};

export const fetchChartDataAction = (userId, timeline) => async (dispatch) => {
  try {
    dispatch({ type: GET_CHART_DATA_REQUEST });

    const { data } = await axiosPost(`dashboard/graphData?user_id=${userId}`, {
      time_period: timeline
    });
    dispatch({ type: GET_CHART_DATA_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: GET_CHART_DATA_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

export const setFundingTargetAction = (userId, fundingData) => async (dispatch) => {
  try {
    dispatch({ type: SET_FUNDING_TARGET_REQUEST });

    const { data } = await axiosPost(`dashboard/setFundingTarget?user_id=${userId}`, fundingData);

    dispatch({ type: SET_FUNDING_TARGET_SUCCESS, payload: data?.data });
    console.log("------funding data-----------", data?.data);
    return data;
  } catch (error) {
    dispatch({
      type: SET_FUNDING_TARGET_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const getBusinessAnalyticsDataAction = (selectedMonth, selectedYear) => async (dispatch) => {
  try {
    dispatch({ type: GET_BUSINESS_ANALYTICS_DATA_REQUEST });

    const { data } = await axiosPost(`dashboard/getAdminFundingData`, {
      month: selectedMonth,
      year: selectedYear
    });

    dispatch({ type: GET_BUSINESS_ANALYTICS_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_BUSINESS_ANALYTICS_DATA_FAIL,
      payload: error?.response?.data?.message,
    });
  }
}

export const getAdminSubmissionDataAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ADMIN_SUBMISSION_DATA_REQUEST });

    const { data } = await axiosGet(`dashboard/getAdminSubmissions`);
    dispatch({ type: GET_ADMIN_SUBMISSION_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_SUBMISSION_DATA_FAIL,
      payload: error?.response?.data?.message,
    });
  }
}