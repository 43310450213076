"use client"
import React, { useState } from 'react';
import { Modal, Select, Input ,Button } from 'antd';

const { Option } = Select;

const FilterModal = ({ isVisible, onClose, onApplyFilter }) => {
  const [selectedCategory, setSelectedCategory] = useState('Select Category');
  const [inputValue, setInputValue] = useState('');

  const handleApplyFilter = () => {
    
    onApplyFilter(selectedCategory, inputValue);
    onClose();
  };

  const handleClearFilters = () => {
    setSelectedCategory(''); // Reset selected category
    setInputValue(''); // Reset input value
  };


  return (
    <Modal
      title="Filter Lenders"
      open={isVisible}
        onCancel={onClose}
      footer={[
        <Button key="clear" onClick={handleClearFilters} style={{ marginRight: '8px' }}>
          Clear
        </Button>,
        <Button className='bg-custom-green' key="apply" type="primary" onClick={handleApplyFilter}>
          Apply
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 16 }}>
        <Select
          placeholder="Select Category"
          value={selectedCategory}
          onChange={(value) => setSelectedCategory(value)}
          style={{ width: '100%' }}
        >
          <Option value="restrictedIndustry">Restricted Industry</Option>
          <Option value="restrictedState">Restricted State</Option>
          <Option value="perferedIndustry">Perfered Industry</Option>
        </Select>
      </div>
      <Input
        placeholder="Enter search value"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Modal>
  );
};

export default FilterModal;
