import { Spin } from 'antd';
import React, { useEffect } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const formatXAxisLabel = (label) => {
  const maxLength = 5; // Adjust the max length as needed
  return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
};

const formatYAxisLabel = (value) => `${value.toLocaleString()}`;

const AdminFundingBar1 = ({ data, heading ,loading }) => {

  
  const formattedData = data.map(item => ({
    ...item,
    uv: typeof item.amount === 'string' ? parseInt(item.amount.replace('')) : item.amount
  }));


  useEffect(()=>{
    console.log("Data got ::",data)
  },[])
  return (
    <div className='bg-white w-[97%] rounded-2xl ml-5 pt-2 mr-5 mb-5'>
      {loading?
       <div className='w-full pt-16 flex justify-center items-center' >
       <Spin />
     </div>  
     :
     <div>
      <div className='mx-6 my-4 text-center text-lender-green-text font-semibold'>
        {heading}
      </div>
      <ResponsiveContainer width="100%" height={250} bottom={20}>
        <BarChart
          data={data}
          margin={{
            top: 20, right: 10, left: 10, bottom: 20,
          }}
          >
          <XAxis axisLine={false} tickLine={false} dataKey="name" tickFormatter={formatXAxisLabel} tick={{ fontSize: 10, fill: '#666', angle : '-20', dy: 10 }} />
          <YAxis 
            axisLine={false}
            tickLine={false}
            tickFormatter={formatYAxisLabel}
            label={{angle: -90, position: 'insideLeft', dy: -10, fill: '#666' }}
            />
          <CartesianGrid horizontal={true} vertical={false} stroke='#C0C0C0' strokeWidth={0.3} />
          {/* <Bar barSize={30} dataKey="pv" fill= '#004629' stackId="a" radius={[2,2, 0, 0]} /> */}
          <Tooltip formatter={(value) => `${value.toLocaleString()}`}  />
          <Bar barSize={30} dataKey="Submission" fill="#00A762" stackId="a" radius={[7, 7, 0, 0]} style={{ pointerEvents: "none" }} />
        </BarChart>
      </ResponsiveContainer>
      </div>
    }
    </div>
  );
};

export default AdminFundingBar1;
