import { SET_SELECTED_TAB, SET_ROLE, SET_CHILD_TAB , SET_USER } from '../types/authTypes';

const initialState = {
  selectedTab: 'Dashboard',
  childSelectedTab: 'Account Settings',
  userId: JSON.parse(localStorage.getItem('sessionData'))?.userId || null,
  name: JSON.parse(localStorage.getItem('sessionData'))?.name || null,
  role: JSON.parse(localStorage.getItem('sessionData'))?.role || 'agent',  // admin , operations , agent
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
            return {
                ...state,
                ...action.payload,
            };
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_CHILD_TAB:
      return {
        ...state,
        childSelectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
