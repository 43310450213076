import { axiosGet, axiosPost } from "../axios";
import { 
    ADD_LEADER_FAIL,
    ADD_LEADER_REQUEST, 
    ADD_LEADER_SUCCESS, 
    GET_LEADER_FAIL, 
    GET_LEADER_REQUEST,
    GET_LEADER_SUCCESS
} from "../types/leadersTypes";


export const addLeaderAction = (userId, leaderData) => async (dispatch) => {
    try {
      dispatch({ type: ADD_LEADER_REQUEST });
  
      const { data } = await axiosPost(`users/createLeader?user_id=${userId}`, leaderData);
      dispatch({ type: ADD_LEADER_SUCCESS, payload: data?.data });
      return data;
    } catch (error) {
      console.log(error, 'error');
      dispatch({
        type: ADD_LEADER_FAIL,
        payload: error.response && error.response.data ? error.response.data : error.message,
      });
      return error.response.data;
    }
  }

  export const getAllLeadersAction = (userId) => async (dispatch) => {
    try {
      dispatch({ type: GET_LEADER_REQUEST });
  
      const { data } = await axiosGet(`users/getLeader?user_id=${userId}`);
      dispatch({ type: GET_LEADER_SUCCESS, payload: data?.data });
      return data;
    } catch (error) {
      dispatch({
        type: GET_LEADER_FAIL,
        payload: error.response && error.response.data ? error.response.data : error.message,
      });
    }
  }