import APIUrl from "../APIUrl";

export const claimDeal = async ( lead_id , user_id) => {
    console.log("UserId ----- " , user_id  ,  "  name "  , " role " )

    try {
        const response = await fetch(`${APIUrl}/deals/claimDeal?user_id=${user_id}&lead_id=${lead_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });


        const data = await response.json();

        if (data.status === 1) {
            console.log("data --------- huy == " , data )
            alert(data.message);
            
            return data;
        } else {
            alert(data.message || "Login failed");
            return data;
          
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};