import { GET_SUBMISSION_DATA_FAIL, GET_SUBMISSION_DATA_REQUEST, GET_SUBMISSION_DATA_SUCCESS } from '../types/submissionChartTypes';

export const getSubmissionDataReducer = (state = {
  loading: false,
  submissionData: [],
  totalSubmission:"0",
  approvedCounts:"0"
}, action) => {
  switch (action.type) {
    case GET_SUBMISSION_DATA_REQUEST:
      return { loading: true };
    case GET_SUBMISSION_DATA_SUCCESS:
      return { loading: false, submissionData: action.payload , totalSubmission :action.totalSubmission ,approvedCounts : action.approvedCounts};
    case GET_SUBMISSION_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}