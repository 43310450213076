import { useEffect, useState , useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, DatePicker, Button } from 'antd';
import moment from 'moment';
import searchIcon from '../../../data/Group 536.png';
import { FiEye } from "react-icons/fi";
import { getOldDealsAction } from '../../../redux/actions/dealsAction';
import { HorizontalFilter } from '../../../common/components/HorizontalFilter';
import { FaCircle } from 'react-icons/fa6';
import { debounce } from 'lodash';
import APIUrl from '../../../atom/APIUrl';

const { RangePicker } = DatePicker;

const options = [
  {
    label: (
      <span className="text-black flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        All
      </span>
    ),
    value: 'All',
  },
  {
    label: (
      <span className="text-green-500 flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        Approved
      </span>
    ),
    value: 'Approved',
  },
  {
    label: (
      <span className="text-orange-300 flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        Pending
      </span>
    ),
    value: 'Pending',
  },
  {
    label: (
      <span className="text-red-500 flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        Declined
      </span>
    ),
    value: 'Declined',
  },

  {
    label: (
      <span className="text-blue-500 flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        Docs requested
      </span>
    ),
    value: 'Contract Sent',
  },
  {
    label: (
      <span className="text-yellow-500 flex justify-start items-center gap-2">
        <FaCircle size="10px" />
        Funded
      </span>
    ),
    value: 'Funded',
  },
];



const OldDealsTable = ({ status }) => {
  const skipEffect = useRef(false);
  const { userId } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState('');
  const [oldDealsTableData, setOldDealsTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // To manage the current page
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [statuses, setStatus] = useState(status);

  // Fetch deals data from Redux state or from the API if not available
  const fetchDealsData = async (params) => {
    setLoading(true);
    const { page = 1, searchQuery = '', startDate, endDate, isSearch = false } = params;
  
    const url = `${APIUrl}/deals/get_old_deal?user_id=${userId}&startDate=${startDate || ''}&endDate=${endDate || ''}&limit=100&status=${statuses}&searchText=${searchQuery}&page=${page}`;
  
    try {
      const response = await fetch(url);
      const result = await response.json();
      console.log('API URL:', url);
  
      if (result.status === 1) {
        if (isSearch || page === 1) {
          setOldDealsTableData(result.data);
        } else {
          setOldDealsTableData((prevData) => [...prevData, ...result.data]);
        }
      } else {
        // Clear the table data when the response status is not 1
        setOldDealsTableData([]);
        console.error('Error fetching deals:', result.message);
      }
    } catch (error) {
      // Clear the table data on API error
      setOldDealsTableData([]);
      console.error('API error:', error);
    }
    setLoading(false);
  };
  

  const handleSearch = debounce((value) => {
    setSearchText(value);
    // setCurrentPage(1); // Reset pagination for new search
    fetchDealsData({
      page: 1,
      searchQuery: value,
      startDate: dateRange.length ? moment(dateRange[0]).format('MM-DD-YYYY') : "",
      endDate: dateRange.length ? moment(dateRange[1]).format('MM-DD-YYYY') : "",
      isSearch: true,
    });
  }, 500);

  const handlePagination = (page) => {

    console.log("Page ---- " , page)
    setCurrentPage(page);
    fetchDealsData({
      page,
      searchQuery: searchText,
      startDate: dateRange.length ? moment(dateRange[0]).format('MM-DD-YYYY') : "",
      endDate: dateRange.length ? moment(dateRange[1]).format('MM-DD-YYYY') : "",
    });
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setCurrentPage(1); // Reset pagination for new filter
    fetchDealsData({
      page: currentPage,
      searchQuery: searchText,
      startDate: dateRange.length ? moment(dateRange[0]).format('MM-DD-YYYY') : undefined,
      endDate: dateRange.length ? moment(dateRange[1]).format('MM-DD-YYYY') : undefined,
      isSearch: true,
    });
  };

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false; // Reset for subsequent navigations
      return;
    }
    fetchDealsData({ page: currentPage });
  }, [userId, statuses, dateRange, currentPage]);
  



  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div className={`flex items-center space-x-2 px-2 py-1 ${(status === 'Declined' || status === 'DECLINED') ? 'bg-declined-bgcolor' : (status === "Approved" || status === "APPROVED") ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
          <span className={`h-2 w-2 ${(status === 'Declined' || status === 'DECLINED') ? 'bg-red-600' : (status === "Approved" || status === "APPROVED") ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
          <span className={`${(status === 'Declined' || status === 'DECLINED') ? 'text-red-600' : (status === "Approved" || status === "APPROVED") ? "text-approved-text" : "text-pendingText"} font-semibold`}>{status}</span>
        </div>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a, b) => a?.company_name?.localeCompare(b?.company_name)
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <span>{moment(date).format('MM/DD/YYYY')}</span>,
      sorter: (a, b) => moment(a.date) - moment(b.date)
    },
    {
      title: "Lead Source",
      dataIndex: "lead_source",
      key: "lead_source",
      render: (lead_source) => <span>{lead_source ?? "-"}</span>,
    },
    {
      title: "Lead Id",
      dataIndex: "lead_id",
      key: "lead_id",
      render: (lead_id) => <span>{lead_id ?? "-"}</span>,
    },
    {
      title: "Lead Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag) => <span>{tag ?? "-"}</span>,
    },
    {
      title: "Open deal",
      dataIndex: "openDeal",
      key: "openDeal",
      render: (deal, record) => (
        <FiEye
          onClick={() => {
            console.log("Open Lead Value ---- ", record);
            navigate('/dealtabs', { state: { deal: record, fromOldTable: true } });
          }}
          alt="Open Deal"
          style={{ cursor: 'pointer', width: '20px', height: '20px' }}
        />
      ),
    }
  ];

  // const handleStatusChange = (value) => {
  //   setStatus(value);
  //   setOldDealsTableData([]); // Clear previous data when status changes
  //   setCurrentPage(1); // Reset pagination when status changes
  //   fetchDealsData(1); // Fetch new data based on the new status
  // };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setOldDealsTableData([]); // Clear previous data when date changes
      setCurrentPage(1); // Reset pagination when date changes
      fetchDealsData(1); // Fetch new data based on the new date range
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchDealsData(currentPage + 1); // Concatenation is the default
  };
  
  const debouncedSearch = debounce((value) => {
    setSearchText(value);
    setCurrentPage(1); // Reset to the first page for a new search
    fetchDealsData(1, value, true); // Fetch new data and replace
  }, 500);

  const handleDateFilter = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setCurrentPage(1); // Reset pagination for new filter
      fetchDealsData({
        page: 1,
        searchQuery: searchText,
        startDate: moment(dates[0]).format('MM-DD-YYYY'),
        endDate: moment(dates[1]).format('MM-DD-YYYY'),
        isSearch: true,
      });
    }
  };
  

  return (
    <div className="flex-grow overflow-y-scroll mb-5">
      <div className="mt-4 w-full">
        <div className="bg-white rounded-tl-xl rounded-tr-xl p-3">
          <div className="flex flex-row justify-center items-center">
            <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
              <img
                src={searchIcon}
                alt="Search Icon"
                className="w-8 h-8 mr-2"
              />
              <input
                type="text"
                placeholder="Search information"
                className="bg-background-color focus:outline-none pl-3 w-full"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <RangePicker
              className="text-black font-bold"
              onChange={handleDateFilter}
            />
          </div>
          <HorizontalFilter options={options} onStatusChange={handleStatusChange} />
        </div>
        <Table
          scroll={{ x: 800 }}
          loading={loading}
          columns={columns}
          dataSource={oldDealsTableData}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: oldDealsTableData.length,
            showSizeChanger: false,
            onChange: handlePagination,
          }}
        />
        {oldDealsTableData.length > 0 && oldDealsTableData.length % 100 === 0 && (
          <Button
            type="primary"
            onClick={() => handlePagination(currentPage + 1)}
            loading={loading}
            style={{ marginTop: 20 }}
          >
            Load More
          </Button>
        )}
      </div>
    </div>
  );
};


export default OldDealsTable;
