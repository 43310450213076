import { ADD_LEAD_FAIL, ADD_LEAD_REQUEST, ADD_LEAD_SUCCESS, GET_ACTIVITY_LOGS_FAIL, GET_ACTIVITY_LOGS_REQUEST, GET_ACTIVITY_LOGS_SUCCESS, GET_ALL_FILES_FAIL, GET_ALL_FILES_REQUEST, GET_ALL_FILES_SUCCESS, GET_ALL_LEADS_FAIL, GET_ALL_LEADS_REQUEST, GET_ALL_LEADS_SUCCESS, GET_INDIVIDUAL_LEAD_FAIL, GET_INDIVIDUAL_LEAD_REQUEST, GET_INDIVIDUAL_LEAD_SUCCESS, POST_CSV_FILE_FAIL, POST_CSV_FILE_REQUEST, POST_CSV_FILE_SUCCESS, POST_FILE_FAIL, POST_FILE_REQUEST, POST_FILE_SUCCESS, RESET_ALL_FILES, SEND_LEAD_TO_OPERATIONS_FAIL, SEND_LEAD_TO_OPERATIONS_REQUEST, SEND_LEAD_TO_OPERATIONS_SUCCESS, UPDATE_LEAD_FAIL, UPDATE_LEAD_REQUEST, UPDATE_LEAD_SUCCESS, GET_DELETE_LEADS_FAIL, GET_DELETE_LEADS_REQUEST, GET_DELETE_LEADS_SUCCESS, } from '../types/leadsTypes';

export const getAllLeadsReducer = (state = {
  loading: false,
  allLeads: []
}, action) => {
  switch (action.type) {
    case GET_ALL_LEADS_REQUEST:
      return { loading: true };
    case GET_ALL_LEADS_SUCCESS:
      return { loading: false, allLeads: action.payload };
    case GET_ALL_LEADS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const addLeadReducer = (state = {
  loading: false,
  lead: {}
}, action) => {
  switch (action.type) {
    case ADD_LEAD_REQUEST:
      return { loading: true };
    case ADD_LEAD_SUCCESS:
      return { loading: false, lead: action.payload };
    case ADD_LEAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const updateLeadReducer = (state = {
  loading: false,
  updateLead: {}
}, action) => {
  switch (action.type) {
    case UPDATE_LEAD_REQUEST:
      return { loading: true };
    case UPDATE_LEAD_SUCCESS:
      return { loading: false, updateLead: action.payload };
    case UPDATE_LEAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getActivittyLogsReducer = (state = {
  loading: false,
  activityLogs: []
}, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LOGS_REQUEST:
      return { loading: true };
    case GET_ACTIVITY_LOGS_SUCCESS:
      return { loading: false, activityLogs: action.payload };
    case GET_ACTIVITY_LOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const sendLeadToOperationsReducer = (state = {
  loading: false,
  sendLeadToOperations: {}
}, action) => {
  switch (action.type) {
    case SEND_LEAD_TO_OPERATIONS_REQUEST:
      return { loading: true };
    case SEND_LEAD_TO_OPERATIONS_SUCCESS:
      return { loading: false, sendLeadToOperations: action.payload };
    case SEND_LEAD_TO_OPERATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const postCsvFileReducer = (state = {
  loading: false,
  postCsvFile: {}
}, action) => {
  switch (action.type) {
    case POST_CSV_FILE_REQUEST:
      return { loading: true };
    case POST_CSV_FILE_SUCCESS:
      return { loading: false, postCsvFile: action.payload };
    case POST_CSV_FILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const uploadFileReducer = (state = {
  loading: false,
  uploadFile: {}
}, action) => {
  switch (action.type) {
    case POST_FILE_REQUEST:
      return { loading: true };
    case POST_FILE_SUCCESS:
      return { loading: false, uploadFile: action.payload };
    case POST_FILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getAllFilesReducer = (state = {
  loading: false,
  allFiles: []
}, action) => {
  switch (action.type) {
    case GET_ALL_FILES_REQUEST:
      return { loading: true };
    case GET_ALL_FILES_SUCCESS:
      return { loading: false, allFiles: action.payload };
    case GET_ALL_FILES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_FILES:
      return { loading: false, allFiles: [] };
    default:
      return state;
  }
}

// delete lead
export const getDeleteLeadReducer = (state = {
  loading: false,
}, action) => {
  switch (action.type) {
    case GET_DELETE_LEADS_REQUEST:
      return { loading: true, error: null };
    case GET_DELETE_LEADS_SUCCESS:
      return { loading: false };
    case GET_DELETE_LEADS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}