// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import { LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import { MdEdit } from 'react-icons/md';
import ProfButton from '../../../data/profileupdate.png';
import { useSelector } from "react-redux";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import ToggleButtonComp from "./ToggleButtonComp";
import AddNewLenderForm from './AddNewLenderForm';
import BackButton from "../../tabScreens/SubmissionBoard/backButton";
import EditLenderForm from "./EditLenderForm";

const EditLender = () => {
    const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('Information');
  const role = useSelector((state) => state.role);
  const lenderDetails = location.state?.lenderDetails;

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    console.log("Tab ---------------  ", tab)
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (lenderDetails) {
      console.log("Lender Details:", lenderDetails);
    }
  }, [lenderDetails]);

  const menuItems = role == 'operations' ?
    [
      "Dashboard",
      "Deals",
      "Submission Board",
      "Lender directory",
      "Compliance",
      "Commision portal"
    ]
    : [
      "Dashboard",
      "Leads",
      "Deals",
      "Submission Board",
      "Lender directory",
      "Compliance",
      "Commision portal"
    ];

  return (
    <div className="flex h-screen">
      <div className="flex space-x-24 w-[278px]">
        <DashboardTab />
      </div>
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow mb-5 mt-8 overflow-y-auto">
        <div className="flex flex-row gap-4 items-center">
  <BackButton />
  <div>Edit Lender Details</div>
</div>
       
          <div className="bg-white rounded-lg py-5 mt-5 px-7 h-[auto]">
          <EditLenderForm
  initialValues={{
    lender_name: lenderDetails?.lender.company,
    owner_firstName: lenderDetails?.lender.ceoFirstName,
    owner_mail: lenderDetails?.lender.ceoMail,
    max_funding_amount: lenderDetails?.maxfundingAmount,
    monthly_minimums: lenderDetails?.monthlyMinimums,
    tier:  lenderDetails?.tier,
    nsf:  lenderDetails?.nsf,
    avg_daily_balance:  lenderDetails?.avgDailyBalance,
    position_toWilling: lenderDetails?.positionToWilling,
    isDaily: lenderDetails?.daily  === "Yes",
    isWeekly: lenderDetails?.weekly  === "Yes",
    isMonthly: lenderDetails?.biweeklyMonthly === "Yes",
    creditScoreMinimum: lenderDetails?.creditScoreMinimum,
    creditScoremaximum : lenderDetails?.creditScoreMinimumValue,
    maxTerm: lenderDetails?.maxTerm,
    tib: lenderDetails?.tib,
    restrictedIndustry: lenderDetails?.restrictedIndustry,
    restrictedState: lenderDetails?.restrictedState,
    perferedIndustry : lenderDetails?.perferedIndustry


  }}
/>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EditLender;