import React, { useState } from 'react';

export const TruncatedCell = ({ value }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const limit = 20;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const getFilteredData = () => {
    const regex = new RegExp(searchTerm, 'i'); // Case-insensitive search
    const items = value.split(/,|\u2022/).map(item => item.trim()); // Split by commas or bullets
    return items.filter(item => regex.test(item));
  };

  // Check if text needs truncating and if there is valid data
  const shouldTruncate = value && value.length > limit && value !== "-";

  return (
    <div>
      <span>
        {shouldTruncate && !isExpanded
          ? `${value.slice(0, limit)}...`
          : value || "-"}
        {shouldTruncate && (
          <span
            onClick={toggleExpansion}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        )}
      </span>

      {/* Modal */}
      {isExpanded && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-lg w-full">
            <h2 className="text-lg font-semibold mb-4">Search Data</h2>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
              className="p-2 border border-gray-300 rounded w-full mb-4"
            />
            {/* List Container with Scrollbar */}
            <div className="max-h-60 overflow-y-auto">
              <ul>
                {getFilteredData().map((item, index) => (
                  <li key={index} className="p-1 border-b border-gray-200">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <button onClick={toggleExpansion} className="mt-4 text-white-500">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TruncatedCell;
