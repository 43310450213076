// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import LeadGridSquare from "../../../common/components/LeadGridSquare";
import RemovedLeadGridSquare from "../../../common/components/removedLeadSquare";
import { useDispatch, useSelector } from "react-redux";
import ComplianceForm from './ComplianceForm';
import { getQueuedLeadsAction, getRemovedLeadsAction } from '../../../redux/actions/complianceAction';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';

const formatPhoneNumber = (number) => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return number; // return original if format fails
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

const Compliance = () => {
  const [queuedLead, setQueuedLead] = useState([]);
  const [removedLead, setRemovedLead] = useState([]);
  const { role } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { loading: queuedLeadsLoading, queuedLeads } = useSelector((state) => state.getQueuedLeads);
  const { loading: removedLeadsLoading, removedLeads } = useSelector((state) => state.getRemovedLeads);

  useEffect(() => {
    let tempRemovedLeads = [];
    if (removedLeads?.length > 0) {
      removedLeads.forEach((lead) => {
        tempRemovedLeads.push({
          id: lead?.lead_id,
          comName: lead?.company_name,
          firstname: lead?.firstName,
          lastname: lead?.lastName,
          email: lead?.email,
          phone: formatPhoneNumber(lead?.phone),
          contractAmount: formatAmount(lead?.contract_amount),
          paymentAmount: formatAmount(lead?.payment_amount)
        });
      });
      setRemovedLead(tempRemovedLeads);
    }
  }, [removedLeads]);

  useEffect(() => {
    let tempQueuedLeads = [];
    if (queuedLeads?.length > 0) {
      queuedLeads.forEach((lead) => {
        tempQueuedLeads.push({
          id: lead?.lead_id,
          comName: lead?.company_name,
          firstname: lead?.firstName,
          lastname: lead?.lastName,
          email: lead?.email,
          phone: formatPhoneNumber(lead?.phone),
          contractAmount: formatAmount(lead?.contract_amount),
          paymentAmount: formatAmount(lead?.payment_amount)
        });
      });
      setQueuedLead(tempQueuedLeads);
    }
  }, [queuedLeads]);

  useEffect(() => {
    dispatch(getQueuedLeadsAction(userId));
    dispatch(getRemovedLeadsAction(userId));
  }, []);

  // const removedLeads = [
  //   {
  //     comName: 'Construction',
  //     firstname: 'Michael',
  //     lastname: 'Jasek',
  //     email: 'mike@mjasek.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Volkswagen',
  //     firstname: 'Thomas Shelby',
  //     lastname: 'Jasek',
  //     email: 'tshelby@volkswagen.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },

  // ]

  // const RemovingQueueLeads = [
  //   {
  //     comName: 'Motorola',
  //     firstname: 'Luke',
  //     lastname: 'Skywalker',
  //     email: 'skywalker@motorola.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Motorola',
  //     firstname: 'Luke',
  //     lastname: 'Skywalker',
  //     email: 'skywalker@motorola.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Motorola',
  //     firstname: 'Luke',
  //     lastname: 'Skywalker',
  //     email: 'skywalker@motorola.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Motorola',
  //     firstname: 'Luke',
  //     lastname: 'Skywalker',
  //     email: 'skywalker@motorola.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  //   {
  //     comName: 'Motorola',
  //     firstname: 'Luke',
  //     lastname: 'Skywalker',
  //     email: 'skywalker@motorola.com',
  //     phone: '9864689768',
  //     contractAmount: '792.372',
  //     paymentAmount: '935.006'
  //   },
  // ]

  return (
    <div className="flex h-auto">
      <DashboardTab />
      {/* </div> */}
      <main className="flex-1 bg-background-color py-6 w-[calc(100% - 278px)] px-7">
        <GoodMorningBar userName="Manish" />
        <div className="flex mt-8 w-full">
          <div className={`bg-white rounded-2xl p-8 pr-3 ${role === "admin" ? "w-full" : "sm:w-[55%] md:w-[65%]"}`}>
            <p className="text-lg font-bold text-lender-green-text mb-2">
              Latest removed leads
            </p>
            <LeadGridSquare leads={removedLead} loading={removedLeadsLoading} />
            <div className="text-lg font-bold text-lender-green-text mb-5 mt-5">
              Leads in queue to be removed
            </div>
            <RemovedLeadGridSquare leads={queuedLead} loading={queuedLeadsLoading} />
          </div>
          {/* Right Part */}
          {
            role === 'admin' ? null :
              <div className="flex-grow ml-4 w-[45%] lg:w-[35%] bg-white rounded-2xl py-8 px-8  items-start justify-center">

                <div className="text-lg font-bold text-lender-green-text mb-5">
                  Remove Lead from masters
                </div>
                <ComplianceForm />
                {/* <div className=" ml-[25%] mt-7 h-9 px-6 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer w-48 ">
                  Submit for removal
                </div> */}
              </div>
          }
        </div>
      </main>
    </div>
  );
};

export default Compliance;
