import { axiosGet } from "../axios";
import { GET_ALL_REPORT_FAIL, GET_ALL_REPORT_REQUEST, GET_ALL_REPORT_SUCCESS } from "../types/reportTypes";



export const getAllReportAction = (userId) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_REPORT_REQUEST });
  
      const { data } = await axiosGet(`commission/getReports?user_id=${userId}`);
      dispatch({ type: GET_ALL_REPORT_SUCCESS, payload: data.details , data:data.totalPaidByLender});
      return data?.data;
    } catch (error) {
      console.error("Error fetching reports:", error);
      dispatch({
        type: GET_ALL_REPORT_FAIL,
        payload: error.response?.data?.message || error.message,
      });
    }
  }