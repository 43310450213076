import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { fetchChartDataAction } from '../../redux/actions/dashboardAction';
import { userIdAtom } from '../../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';

const TIMELINES = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
};

const DualBarChartComponent = () => {
  const [data, setData] = useState([])
  const [timeline, setTimeline] = useState('Week');
  const [range, setRange] = useState([moment(new Date()).subtract(1, "days"), moment(new Date())])
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  const { loading: chartDataLoading, chartData } = useSelector(state => state.fetchChartData)

  useEffect(() => {
    dispatch(fetchChartDataAction(userId, timeline === "Week" ? "weekly" : timeline === "Monthly" ? "month" : "yearly"))
  }, [timeline])

  useEffect(() => {
    if (chartData) {
      switch (timeline) {
        case TIMELINES.WEEK:
          const updatedChartDataWeek = chartData?.map((item) => ({
            name: moment(item.date).format('ddd'), // Day of the week
            commission: item.deal_count,
          }));
          setData(updatedChartDataWeek);
          break;
          
        case TIMELINES.MONTH:
          const updatedChartDataMonth = chartData?.map((item) => ({
            name: moment(item.date).format('MMM'), // Month abbreviation
            commission: item.deal_count,
          }));
          setData(updatedChartDataMonth);
          break;
  
        case TIMELINES.YEAR:
          const updatedChartDataYear = chartData?.map((item) => ({
            name: moment(item.date).format('YYYY'), // Full year
            commission: item.deal_count,
          }));
          setData(updatedChartDataYear);
          break;
  
        case TIMELINES.DAY: // New case for hours of the day
          const updatedChartDataDay = chartData?.map((item) => ({
            name: moment(item.date).format('h A'), // Hour in 12-hour format with AM/PM
            commission: item.deal_count,
          }));
          setData(updatedChartDataDay);
          break;
  
        default:
          break;
      }
    }
  }, [chartData, timeline]);
  
  // useEffect(() => {
  //   switch (timeline) {
  //     // case TIMELINES.DAY:
  //     //   setRange([moment(new Date()).subtract(1, "days"), moment(new Date())])
  //     //   break
  //     case TIMELINES.WEEK:
  //       setRange([moment(new Date()).subtract(1, "weeks"), moment(new Date())])
  //       break
  //     case TIMELINES.MONTH:
  //       setRange([moment(new Date()).subtract(1, "months"), moment(new Date())])
  //       break
  //     case TIMELINES.YEAR:
  //       setRange([moment(new Date()).subtract(1, "years"), moment(new Date())])
  //       break
  //     default:
  //       setRange([moment(new Date()).subtract(1, "days"), moment(new Date())])
  //       break
  //   }
  // }, [timeline])

  // useEffect(() => {
  //   const now = moment()
  //   const data = []

  //   switch (timeline) {
  //     // case TIMELINES.DAY:
  //     //   for (let i = 1; i <= 24; i++) {
  //     //     // const hour = now.clone().startOf('day').add(i, 'hours')
  //     //     const hour = now.clone().subtract(24 - i, 'hours');
  //     //     data.push({
  //     //       name: hour.format('HH:mm'),
  //     //       pv: Math.floor(Math.random() * 1000),
  //     //       uv: Math.floor(Math.random() * 1000),
  //     //       amt: Math.floor(Math.random() * 1000),
  //     //     })
  //     //   }
  //     //   break
  //     case TIMELINES.WEEK:
  //       for (let i = 1; i <= 7; i++) {
  //         // const day = now.clone().startOf('week').add(i, 'days')
  //         const day = now.clone().subtract(7 - i, 'days');
  //         data.push({
  //           name: day.format('ddd'),
  //           pv: Math.floor(Math.random() * 1000),
  //           uv: Math.floor(Math.random() * 1000),
  //           amt: Math.floor(Math.random() * 1000),
  //         })
  //       }
  //       break
  //     case TIMELINES.MONTH:
  //       for (let i = 1; i <= 30; i++) {
  //         // const day = now.clone().startOf('month').add(i, 'days')
  //         const day = now.clone().subtract(30 - i, 'days');
  //         data.push({
  //           name: day.format('DD'),
  //           pv: Math.floor(Math.random() * 1000),
  //           uv: Math.floor(Math.random() * 1000),
  //           amt: Math.floor(Math.random() * 1000),
  //         })
  //       }
  //       break
  //     case TIMELINES.YEAR:
  //       for (let i = 1; i <= 12; i++) {
  //         // const month = now.clone().startOf('year').add(i, 'months')
  //         const month = now.clone().subtract(12 - i, 'months');
  //         data.push({
  //           name: month.format('MMM'),
  //           pv: Math.floor(Math.random() * 1000),
  //           uv: Math.floor(Math.random() * 1000),
  //           amt: Math.floor(Math.random() * 1000),
  //         })
  //       }
  //       break
  //     default:
  //       break
  //   }
  //   setData(data)
  // }, [timeline])

  const handleSelect = (option) => {
    setTimeline(option);
  };

  return (
    <div className='w-full bg-white mt-5 rounded-2xl'>
      <div className='flex justify-between'>
        <div className='mt-3 ml-8 text-green-text font-semibold'>
          Your Progress
        </div>
        <div className='flex mt-3 mr-8 rounded-lg bg-[#F0F5F3] text-green-text font-semibold px-2 py-1.5'>
        <div
    className={`px-2.5 py-0.5 rounded-lg cursor-pointer ${timeline === TIMELINES.DAY ? 'bg-[#00A762] text-white' : 'bg-[transparent]'}`}
    onClick={() => handleSelect(TIMELINES.DAY)}
  >
    Day
  </div>
          <div
            className={`px-2.5 py-0.5 rounded-lg cursor-pointer ${timeline === 'Week' ? 'bg-[#00A762] text-white' : 'bg-[transparent]'}`}
            onClick={() => handleSelect('Week')}
          >
            Week
          </div>
          <div
            className={`px-2.5 py-0.5 rounded-lg cursor-pointer ${timeline === 'Month' ? 'bg-[#00A762] text-white' : 'bg-[transparent]'}`}
            onClick={() => handleSelect('Month')}
          >
            Month
          </div>
          <div
            className={`px-2.5 py-0.5 rounded-lg cursor-pointer ${timeline === 'Year' ? 'bg-[#00A762] text-white' : 'bg-[transparent]'}`}
            onClick={() => handleSelect('Year')}
          >
            Year
          </div>
        </div>
      </div>
      <ResponsiveContainer width="97%" height={300} bottom={20}>
        <BarChart
          data={data}
          margin={{
            top: 50, right: 10, left: 10, bottom: 10,
          }}
        >
          <XAxis axisLine={false} tickLine={false} dataKey="name" />
          <CartesianGrid horizontal={true} vertical={false} color='#C0C0C0' height={0.3} />
          
          <Tooltip formatter={(value) => `$${value.toLocaleString()}`}  />
          {/* <Bar barSize={30} dataKey="pv" fill='#004629' stackId="a" radius={[2, 2, 0, 0]} /> */}
          <Bar barSize={30} dataKey="commission" fill="#00A762" stackId="a" radius={[7, 7, 0, 0]} />
          <Legend verticalAlign="top" align='right' height={36} />
          {/* <Tooltip /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DualBarChartComponent;
