export const CREATE_NEW_TASK_REQUEST = "CREATE_NEW_TASK_REQUEST";
export const CREATE_NEW_TASK_SUCCESS = "CREATE_NEW_TASK_SUCCESS";
export const CREATE_NEW_TASK_FAIL = "CREATE_NEW_TASK_FAIL";

export const GET_ALL_TASKS_REQUEST = "GET_ALL_TASKS_REQUEST";
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_FAIL = "GET_ALL_TASKS_FAIL";

export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const GET_CHART_DATA_REQUEST = "GET_CHART_DATA_REQUEST";
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS";
export const GET_CHART_DATA_FAIL = "GET_CHART_DATA_FAIL";

export const SET_FUNDING_TARGET_FAIL = "SET_FUNDING_TARGET_FAIL";
export const SET_FUNDING_TARGET_REQUEST = "SET_FUNDING_TARGET_REQUEST";
export const SET_FUNDING_TARGET_SUCCESS = "SET_FUNDING_TARGET_SUCCESS";

export const GET_BUSINESS_ANALYTICS_DATA_REQUEST = "GET_BUSINESS_ANALYTICS_DATA_REQUEST";
export const GET_BUSINESS_ANALYTICS_DATA_SUCCESS = "GET_BUSINESS_ANALYTICS_DATA_SUCCESS";
export const GET_BUSINESS_ANALYTICS_DATA_FAIL = "GET_BUSINESS_ANALYTICS_DATA_FAIL";

export const GET_ADMIN_SUBMISSION_DATA_REQUEST = "GET_SUBMISSION_DATA_REQUEST";
export const GET_ADMIN_SUBMISSION_DATA_SUCCESS = "GET_SUBMISSION_DATA_SUCCESS";
export const GET_ADMIN_SUBMISSION_DATA_FAIL = "GET_SUBMISSION_DATA_FAIL";

export const GET_TOP_LEADS_SUCCESS = "GET_TOP_LEADS_SUCCESS";
export const GET_TOP_LEADS_FAILURE = "GET_TOP_LEADS_FAILURE";