import React from 'react';

const LevelBar = ({ achievedPercentage }) => {
  return (
    <div className="w-full bg-level-background rounded-lg h-7 overflow-hidden">
      <div
        className="bg-custom-green h-full rounded-lg"
        style={{ width: `${achievedPercentage}%` }}
      ></div>
    </div>
  );
};

export default LevelBar;
