import { axiosGet, axiosPost } from '../axios';
import {
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS
} from '../types/userTypes';

export const getUserDetails = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_DETAILS_REQUEST });

    const { data } = await axiosGet(`users/getuser?user_id=${userId}`);
    dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const updateUserDetails = (userId, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_DETAILS_REQUEST });

    const { data } = await axiosPost(`users/editUser?user_id=${userId}`, userData);

    dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: UPDATE_USER_DETAILS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}