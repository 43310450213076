import axios from 'axios';
import { axiosGet, axiosPost } from '../axios';
import {
  APPROVE_DEAL_FAIL,
  APPROVE_DEAL_REQUEST,
  APPROVE_DEAL_SUCCESS,
  DECLINE_DEAL_FAIL,
  DECLINE_DEAL_REQUEST,
  DECLINE_DEAL_SUCCESS,
  GET_OLD_DEALS_FAIL,
  GET_OLD_DEALS_REQUEST,
  GET_OLD_DEALS_SUCCESS,
  GET_OLD_LENDER_BY_DEAL_ID_FAIL,
  GET_OLD_LENDER_BY_DEAL_ID_REQUEST,
  GET_OLD_LENDER_BY_DEAL_ID_SUCCESS,
  GET_USER_DEALS_FAIL,
  GET_USER_DEALS_REQUEST,
  GET_USER_DEALS_SUCCESS,
  SEND_DEAL_TO_SUBMISSION_BOARD_FAIL,
  SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST,
  SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS
} from '../types/dealsTypes';

export const getUserDealsAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_DEALS_REQUEST });

    const { data } = await axiosGet(`/deals/getUserLeads?user_id=${userId}`);

    dispatch({ type: GET_USER_DEALS_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: GET_USER_DEALS_FAIL,
      payload: error.response && error.response.data.message
    });
  }
}

export const getAllDealsAction = (userId , role) => async (dispatch) => {
  console.log("Role --- ----" , role)
  try {
    dispatch({ type: GET_USER_DEALS_REQUEST });

    const { data } = await axiosGet(`/deals/${ role === 'operations' || role === 'opAdmin' ? 'getClaimedDeals' :  'getAllDeals'}?user_id=${userId}`);

    dispatch({ type: GET_USER_DEALS_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: GET_USER_DEALS_FAIL,
      payload: error.response && error.response.data.message
    });
  }
}

export const getOldDealsAction = (userId , filters) => async (dispatch) => {
  try {

    const { page, limit, startDate, endDate, status, searchText } = filters;
    dispatch({ type: GET_OLD_DEALS_REQUEST });

    const { data } = await axiosGet(`deals/get_old_deal?user_id=${userId}`);

    dispatch({ type: GET_OLD_DEALS_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: GET_OLD_DEALS_FAIL,
      payload: error.response && error.response.data.message
    });
  }
}

export const getOldLenderByDealIdAction = (userId, dealId) => async (dispatch) => {
  try {
    dispatch({ type: GET_OLD_LENDER_BY_DEAL_ID_REQUEST });

    const { data } = await axiosGet(`deals/get_old_lenders?user_id=${userId}&lender_user_id=${dealId}`);

    dispatch({ type: GET_OLD_LENDER_BY_DEAL_ID_SUCCESS, payload: data?.lenders });
  } catch (error) {
    dispatch({
      type: GET_OLD_LENDER_BY_DEAL_ID_FAIL,
      payload: error.response && error.response.data.message
    });
  }
}

export const sendDealToSubmissionBoardAction = (userId, dealId,template) => async (dispatch) => {
  try {
    dispatch({ type: SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST });
    const data  = await axiosPost(`deals/moveTosubmissionBoard`, {
      user_id: userId,
        leadId: dealId ,
      template: template});
      console.log("Data for email::" ,data)
    dispatch({ type: SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: SEND_DEAL_TO_SUBMISSION_BOARD_FAIL,
      payload: error.response && error.response?.data?.message
    });
  }
}

export const approveDealAction = (userId, dealId) => async (dispatch) => {
  try {
    dispatch({ type: APPROVE_DEAL_REQUEST });
    const { data } = await axiosGet(`deals/approveDeals?user_id=${userId}&lead_id=${dealId}`);

    dispatch({ type: APPROVE_DEAL_SUCCESS, payload: data?.deal });
    return data;
  } catch (error) {
    dispatch({
      type: APPROVE_DEAL_FAIL,
      payload: error.response && error.response?.data?.message
    });
    return error.response?.data;
  }
}

export const declineDealAction = (userId, dealId,reason) => async (dispatch) => {
  try {
    dispatch({ type: DECLINE_DEAL_REQUEST });

    // const { data } = await axiosGet(`deals/declineDeals`}
    const { data } = await axiosPost(`deals/declineDeals?user_id=${userId}&lead_id=${dealId}`,
      {
        // user_id:userId,
        // lead_id:dealId,
        comment:reason
      }
    );

    dispatch({ type: DECLINE_DEAL_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: DECLINE_DEAL_FAIL,
      payload: error.response && error.response?.data?.message
    });
    return error.response?.data;
  }
}