// Dashboard.js
import React, { useState } from "react";
import '../../../common/components/Calender.css';
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import { useNavigate } from "react-router-dom";
import { LabeledDropdown, LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import { MdEdit } from 'react-icons/md';
import ProfButton from '../../../data/profileupdate.png';
import { useSelector } from "react-redux";
import { Button, Empty, Spin } from 'antd';
import CreateFundungAmountTask from "../../../common/components/SettingsComponent/CreateFundungAmountTask";

const SetFundingTarget = () => {

  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const [showCreateFundingModal, setShowCreateFundingModal] = useState(false);

  const menuItems = [
    "Dashboard",
    "Leads",
    "Deals",
    "Submission Board",
    "Lender directory",
    "Compliance",
    "Commision portal",
  ];

  const LabeledTextarea = ({ label, placeholder, className }) => (
    <div className="flex flex-col w-full">
      <label className=" font-medium text-good-morning-text bg-white">{label}</label>
      <textarea 
        placeholder={placeholder} 
        className={`border border-gray-300 bg-white w-full  mt-3 h-56 resize-none rounded-md ${className}`} 
      />
    </div>
  );
  const items = role === "admin" 
    ? ["Account Settings", "Org Chart", "Add new role", "Set funding target"] 
    : ["Account Settings", "Compliance Settings", "Email templates settings", "Sequences Settings"];
  return (
    <div className="flex h-screen bg-black">
       {showCreateFundingModal && (
              <CreateFundungAmountTask
                showCreateFundingModal={showCreateFundingModal}
                setShowCreateFundingModal={setShowCreateFundingModal}
              />
            )}
      <div className="flex space-x-24 w-80">
        <SecondaryNavigation items={menuItems} />
        <ChildNavigation items={items} label={'Settings'} />
      </div>
      <main className="flex-1 bg-background-color p-6 pl-12 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex justify-between w-full mr-9 my-6 ">
        
        </div>
          <div className="flex-grow mb-5 overflow-y-auto">
            <div className="bg-white rounded-lg py-5 px-7 h-auto">
                <Button
                  className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
                  onClick={() => setShowCreateFundingModal(true)}
                >
                  Set Funding Target
                </Button>
            </div>
          </div>
      </main>
    </div>
  );
};

export default SetFundingTarget;
