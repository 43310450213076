import { Modal } from 'antd';
import { FaCheck } from "react-icons/fa";
import Bell from "../../../data/Bell, Notifications.png";
import APIUrl from "../../../atom/APIUrl";
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useSelector } from 'react-redux';

const AllTaskModal = ({ alltasks, setAllTasks, showAllTasksModal, setShowAllTasksModal ,refreshTasks }) => {
  const { userId } = useSelector((state) => state.auth);

  const onClose = () => {
    setShowAllTasksModal(false);
  };

  const handleSelectTask = async (taskId) => {
    console.log(`Selecting task: ${taskId}`); 
    try {
      const response = await fetch(`${APIUrl}/tasks/markTaskdone?user_id=${userId}&task_id=${taskId}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error('Failed to mark task as done');
      }
  
      const result = await response.json();
      if (result.message && result.message.includes('successfully')) {
        const updatedTasks = alltasks.map(task => ({
          ...task,
          isdone: task._id === taskId ? true : task.isdone 
        }));
        setAllTasks(updatedTasks);
        refreshTasks()
      } else {
        console.error('Failed to update task:', result.message);
      }
    } catch (error) {
      console.error('Error marking task as done:', error);
    }
  };
  
  return (
    <Modal
      open={showAllTasksModal}
      onCancel={onClose}
      width={700}
      footer={null}
      centered
      title={<h4 className='font-medium'>All Tasks</h4>}
    >
      {alltasks?.map((t) => (
        <div key={t._id} className={`w-full flex justify-between items-center py-2 px-2 rounded-2xl ${t.isdone=== true ? 'bg-custom-green' : 'bg-background-color'} mb-2`}>
          <div className="flex">
            <div className="rounded-full h-10 w-10 bg-white p-1">
              <img src={Bell} className="h-8 w-8" alt="Notification Bell" />
            </div>
            <div className={`pl-7 py-2 font-semibold text-sm ${t.isdone===true ? 'text-white' : 'text-green-text'}`}>
              {t.task}
            </div>
          </div>
          <button 
            onClick={() => handleSelectTask(t._id)} 
            className="bg-white rounded-full h-7 w-7 flex items-center justify-center cursor-pointer"
          >
            {t.isdone===true && <FaCheck className='text-custom-green text-lg' />}
          </button>
        </div>
      ))}
    </Modal>
  );
};

export default AllTaskModal;
