import { Button, Modal } from 'antd'
import { addNewLenderToDealAction, getDealsByLenderAction } from '../../../../redux/actions/lenderAction'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const AddNewLenderConfirmModal = ({ userId, deal, selectedNewLender, showAddNewLenderConfirmModal, setShowAddNewLenderConfirmModal }) => {

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.addNewLenderToDeal)

  const handleConfirm = async () => {
    const result = await dispatch(addNewLenderToDealAction(userId, deal?._id, selectedNewLender?._id))
    console.log(result, 'result')
    if (result?.status === 1) {
      setShowAddNewLenderConfirmModal(false)
      toast.success('New lender added to deal successfully')
      dispatch(getDealsByLenderAction(userId, deal?._id));
    } else {
      toast.error('Failed to add new lender to deal')
    }
  }
  return (
    <Modal
      centered
      footer={null}
      open={showAddNewLenderConfirmModal}
      onCancel={() => setShowAddNewLenderConfirmModal(false)}
      onOk={() => setShowAddNewLenderConfirmModal(false)}
    >
      <div className=''>
        <p className='text-base'>Are you sure you want to add {" "}
          <span className='capitalize font-semibold'>{selectedNewLender.lender_name}</span>
          {" "}as a lender to the {" "}
          <span className='capitalize font-semibold'>{deal?.company_name}</span>
          {" "}deal?
        </p>
      </div>
      <div className='mt-4 flex justify-end items-center gap-3'>
        <Button
          onClick={() => setShowAddNewLenderConfirmModal(false)}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={() => handleConfirm()}
          className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default AddNewLenderConfirmModal