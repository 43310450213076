import {
  APPROVE_LENDER_IN_SB_FAIL,
  APPROVE_LENDER_IN_SB_REQUEST,
  APPROVE_LENDER_IN_SB_SUCCESS,
  DECLINE_LENDER_IN_SB_FAIL,
  DECLINE_LENDER_IN_SB_REQUEST,
  DECLINE_LENDER_IN_SB_SUCCESS,
  GET_ALL_SUBMISSIONS_FAIL,
  GET_ALL_SUBMISSIONS_REQUEST,
  GET_ALL_SUBMISSIONS_SUCCESS,
  SET_SHOWN_OLD_DEALS_BUTTON,
  GET_SUBMISSIONS_BOARD_DEAL_FAIL,
  GET_SUBMISSIONS_BOARD_DEAL_REQUEST,
  GET_SUBMISSIONS_BOARD_DEAL_SUCCESS,
} from '../types/submissionBoardTypes';

export const getAllSubmissionsReducer = (state = {
  loading: true,
  submissions: []
}, action) => {
  switch (action.type) {
    case GET_ALL_SUBMISSIONS_REQUEST:
      return { loading: true };
    case GET_ALL_SUBMISSIONS_SUCCESS:
      return { loading: false, submissions: action.payload };
    case GET_ALL_SUBMISSIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const setShownOldDealsButtonReducer = (state = {
  showOldDeals: false
}, action) => {
  switch (action.type) {
    case SET_SHOWN_OLD_DEALS_BUTTON:
      return { showOldDeals: action.payload };
    default:
      return state;
  }
}

export const approveLenderInSubmissionBoardReducer = (state = {
  loading: false,
  approveLenderInSB: []
}, action) => {
  switch (action.type) {
    case APPROVE_LENDER_IN_SB_REQUEST:
      return { loading: true };
    case APPROVE_LENDER_IN_SB_SUCCESS:
      return { loading: false, approveLenderInSB: action.payload };
    case APPROVE_LENDER_IN_SB_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const declineLenderInSubmissionBoardReducer = (state = {
  loading: false,
  declineLenderInSB: []
}, action) => {
  switch (action.type) {
    case DECLINE_LENDER_IN_SB_REQUEST:
      return { loading: true };
    case DECLINE_LENDER_IN_SB_SUCCESS:
      return { loading: false, declineLenderInSB: action.payload };
    case DECLINE_LENDER_IN_SB_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
//submission bord deal action
export const getSubmissionsBoardDealReducer = (state = {
  loading: true,
  submissionsDeals: []
}, action) => {
  switch (action.type) {
    case GET_SUBMISSIONS_BOARD_DEAL_REQUEST:
      return { loading: true };
    case GET_SUBMISSIONS_BOARD_DEAL_SUCCESS:
      return { loading: false, submissionsDeals: action.payload };
    case GET_SUBMISSIONS_BOARD_DEAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
export const getApprovedSubmissionsBoardDealReducer = (state = {
  loading: true,
  submissionsDeals: []
}, action) => {
  switch (action.type) {
    case GET_SUBMISSIONS_BOARD_DEAL_REQUEST:
      return { loading: true };
    case GET_SUBMISSIONS_BOARD_DEAL_SUCCESS:
      return { loading: false, submissionsDeals: action.payload };
    case GET_SUBMISSIONS_BOARD_DEAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}