import {GET_ALL_COMMISION_FAIL, GET_ALL_COMMISION_REQUEST, GET_ALL_COMMISION_SUCCESS, GET_COMMISION_FAIL, GET_COMMISION_REQUEST, GET_COMMISION_SUCCESS,  GET_CONFIRM_PAID_FAIL,  GET_CONFIRM_PAID_REQUEST,  GET_CONFIRM_PAID_SUCCESS,  POST_REQUEST_COMMISION_FAIL, POST_REQUEST_COMMISION_REQUEST, POST_REQUEST_COMMISION_SUCCESS} from '../types/commisionTypes';
  
  export const getCommisionReducer = (state = {
    loading: true,
    commissions: []
  }, action) => {
    switch (action.type) {
      case GET_COMMISION_REQUEST:
        return { loading: true };
      case GET_COMMISION_SUCCESS:
        return { loading: false, commissions: action.payload };
      case GET_COMMISION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  
  export const requestCommisionReducer = (state = {
    loading: false,
    postCommission: {},
  }, action) => {
    switch (action.type) {
      case POST_REQUEST_COMMISION_REQUEST:
        return { loading: true };
        case POST_REQUEST_COMMISION_SUCCESS:
          return { loading: false, success: true, postCommission: action.payload };
          case POST_REQUEST_COMMISION_FAIL:
            return { loading: false, error: action.payload };
      default:
        return state;
      }
    }
    export const getAllCommisionReducer = (state = {
      loading: true,
      allCommissions: []
    }, action) => {
      switch (action.type) {
        case GET_ALL_COMMISION_REQUEST:
          return { loading: true };
        case GET_ALL_COMMISION_SUCCESS:
          return { loading: false, allCommissions: action.payload };
        case GET_ALL_COMMISION_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

    export const paidReducer = (state = { allCommissions: [] }, action) => {
      switch (action.type) {
        case GET_CONFIRM_PAID_REQUEST:
          return { ...state, loading: true };
        case GET_CONFIRM_PAID_SUCCESS:
          return {
            ...state,
            loading: false,
            allCommissions: state.allCommissions.map(commission => 
              commission.lenderdealid === action.payload.lenderdealid
                ? { ...commission, paid: 'Yes' }
                : commission
            ),
          };
        case GET_CONFIRM_PAID_FAIL:
          return { ...state, loading: false, error: action.payload };
        default:
          return state;
      }
    };