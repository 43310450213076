import { useState } from "react";
import { Button } from "antd";
import axios from "axios";
import APIUrl from "../../atom/APIUrl";
import { useSelector } from "react-redux";

export const AddCommentModal = ({ isOpen, lender, onClose }) => {

  const { userId } = useSelector((state) => state.auth);
  const [comment, setComment] = useState(""); // State to store the comment
  const [isLoading, setIsLoading] = useState(false); // State to handle loading state

  // Function to handle saving the comment
  const handleSave = async () => {
    console.log("Lender Id --- ", lender?.edit._id);
    if (!comment.trim()) {
      alert("Comment cannot be empty.");
      return;
    }
  
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${APIUrl}/lenders/addComment?user_id=${userId}&lender_id=${lender?.edit._id}`, 
        { comment }
      );
      console.log("Response:", response.data);
  
      alert(response.data.message);
      setComment(""); // Reset the comment input
      onClose(); // Close the modal
     
    } catch (error) {
      console.error("Error adding comment:", error.message);
      alert("Failed to add comment. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-lg w-1/3">
        <h2 className="text-lg font-bold mb-4">Add Comment</h2>
        <p className="mb-2">
          Add a comment for <span className="font-semibold">{lender?.company}</span>
        </p>
        <textarea
          className="w-full h-20 border border-gray-300 p-2 rounded mb-4"
          placeholder="Enter your comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <div className="flex justify-end gap-2">
          <Button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className="bg-green-600 text-white px-4 py-2 rounded"
            loading={isLoading} // Show loading spinner on button
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
