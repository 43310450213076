export const GET_ALL_SUBMISSIONS_REQUEST = 'GET_ALL_SUBMISSIONS_REQUEST';
export const GET_ALL_SUBMISSIONS_SUCCESS = 'GET_ALL_SUBMISSIONS_SUCCESS';
export const GET_ALL_SUBMISSIONS_FAIL = 'GET_ALL_SUBMISSIONS_FAIL';

export const SET_SHOWN_OLD_DEALS_BUTTON = 'SET_SHOWN_OLD_DEALS_BUTTON';

export const APPROVE_LENDER_IN_SB_REQUEST = 'APPROVE_LENDER_IN_SB_REQUEST';
export const APPROVE_LENDER_IN_SB_SUCCESS = 'APPROVE_LENDER_IN_SB_SUCCESS';
export const APPROVE_LENDER_IN_SB_FAIL = 'APPROVE_LENDER_IN_SB_FAIL';

export const DECLINE_LENDER_IN_SB_REQUEST = 'DECLINE_LENDER_IN_SB_REQUEST';
export const DECLINE_LENDER_IN_SB_SUCCESS = 'DECLINE_LENDER_IN_SB_SUCCESS';
export const DECLINE_LENDER_IN_SB_FAIL = 'DECLINE_LENDER_IN_SB_FAIL';


export const GET_SUBMISSIONS_BOARD_DEAL_REQUEST = 'GET_SUBMISSIONS_BOARD_DEAL_REQUEST';
export const GET_SUBMISSIONS_BOARD_DEAL_SUCCESS = 'GET_SUBMISSIONS_BOARD_DEAL_SUCCESS';
export const GET_SUBMISSIONS_BOARD_DEAL_FAIL = 'GET_SUBMISSIONS_BOARD_DEAL_FAIL';
