import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './backButton.css'; // Ensure you create this CSS file

const BackButton = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(-1);
  };

  return (
    <span>

    <button className="back-button" onClick={handleReturn}>
      <FontAwesomeIcon icon={faArrowLeft} className="icon"  />
    </button>
    </span>
  );
};

export default BackButton;
