// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import { useNavigate } from "react-router-dom";
import { LabeledDropdown, LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import { MdEdit } from 'react-icons/md';
import ProfButton from '../../../data/profileupdate.png';
import { signupMail, userIdAtom } from "../../../atom/authuserData/userdata";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getAllLeadersAction } from "../../../redux/actions/leaderAction";
import { useDispatch, useSelector } from "react-redux";

const AddNewRole = () => {

  const navigate = useNavigate();
  const user_id = useRecoilValue(userIdAtom);
  const [isLoading , setIsLoading] = useState(false);
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [newrole, setRole] = useState("");
  const [errors, setErrors] = useState({});
  const { role } = useSelector((state) => state.auth);
  const [selectedLeader, setSelectedLeader] = useState("");


  const dispatch = useDispatch();
  const leaders = useSelector((state)=>state.getLeader.allLeaders);


  // const leaders = [
  //   { value: 'leader1', label: 'Leader 1' },
  //   { value: 'leader2', label: 'Leader 2' },
  //   { value: 'leader3', label: 'Leader 3' },
  //   // Add more leaders as needed
  // ];

  const leaderOptions = leaders?.map((leader) => ({
    value: leader?._id || "",
    label: `${leader?.firstName || "Unknown"} ${leader?.lastName || ""}`
  })) || [];
  
  useEffect(() => {
    console.log("Leaders fetched:", leaders);
    dispatch(getAllLeadersAction(user_id));
  }, [dispatch, user_id]);


  useEffect(() => {
    console.log("Leaders ::",leaders)
    dispatch(getAllLeadersAction(user_id));
  }, [dispatch, user_id]);

  const validateForm = () => {
    let formErrors = {};

    if (!name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      formErrors.email = "Invalid email address";
    }

    if (newrole == "") {
      console.log("new ROle " , newrole)
      formErrors.newrole = "Role is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };
  const handleSubmit =  async () => {
    
    if (validateForm()) {
      setIsLoading(true);
      const data =  await signupMail(email, name, newrole, user_id,leaders);
      console.log("signup Email data ----- " , data )
      if(data.status == 1){
        setname("")
        setRole("Select Role")
        setEmail("")
        setIsLoading(false)
      }else{
       
        setIsLoading(false)
        
      }
      alert(data.message);
      
    }
  };

  const menuItems = [
    "Dashboard",
    "Leads",
    "Deals",
    "Submission Board",
    "Lender directory",
    "Compliance",
    "Commision portal",
  ];

  const LabeledTextarea = ({ label, placeholder, className }) => (
    <div className="flex flex-col w-full">
      <label className=" font-medium text-good-morning-text bg-white">{label}</label>
      <textarea 
        placeholder={placeholder} 
        className={`border border-gray-300 bg-white w-full  mt-3 h-56 resize-none rounded-md ${className}`} 
      />
    </div>
  );

  return (
    <div className="flex h-screen bg-black">
      <div className="flex space-x-24 w-80">
        <SecondaryNavigation items={menuItems} />
          <ChildNavigation items={ role == 'opAdmin' ? ["Account Settings", "Add new role" ] : ["Account Settings","Org Chart", "Add new role", "Set funding target"]} label={'Settings'} />
      </div>
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow mx-2 mb-5 mt-9">
          <div className="relative bg-white rounded-lg py-5 px-7 pb-32 mx-7">
            <div className="flex justify-between mb-3">
              <div className="text-lg font-semibold text-good-morning-text mb-2">
               { role == 'opAdmin' ? 'Enter new operation \'s role' :  'Enter new role details'}
              </div>
              {
                role == 'opAdmin' ?
                null :
            <Button className="bg-custom-green text-white" onClick={() => navigate("/add-leader")}>
                  Add new leader
                  <PlusOutlined />
                </Button>
}
            </div>
            <div className="space-y-7">
              <div className="flex space-x-4">
                <LabeledInput
                  label={'Name'}
                  placeholder={'Enter full name'}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              </div>
              <div className="flex space-x-4">
                <LabeledInput
                  label={'Email'}
                  placeholder={'example@gmail.com'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="flex space-x-4">
                <LabeledDropdown
                  label={'Role'}
                  options={ role == "opAdmin"  ? [
                    { value: '', label: 'Select role' },
                    { value: 'operations', label: 'Operations' },
                    // Add more options as needed
                  ] : [
                    { value: '', label: 'Select role' },
                    { value: 'agent', label: 'Agent' },
                    { value: 'operations', label: 'Operations' },
                    { value: 'opAdmin', label: 'Operation\'s Admin' },
                    // Add more options as needed
                  ]}
                  value={newrole}
                  onChange={(e) => setRole(e.target.value)}
                />
                {errors.newrole && <p className="text-red-500 text-sm">{errors.newrole}</p>}
              </div>
              {newrole === 'agent' && (
                <div className="flex space-x-4">
                  <LabeledDropdown
                    label={'Select Leader'}
                    options={leaderOptions}
                    value={selectedLeader}
                    onChange={(e) => setSelectedLeader(e.target.value)}
                  />
                  {errors.selectedLeader && <p className="text-red-500 text-sm">{errors.selectedLeader}</p>}
                </div>
              )}
            </div>
            <div className="relative bg-white">
              <div
                onClick={handleSubmit}
                className="absolute top-10 right-8  h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer"
              >
               {isLoading ? "Loading... " : "Submit" }
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddNewRole;
