import { ADD_LEADER_FAIL, ADD_LEADER_REQUEST, ADD_LEADER_SUCCESS, GET_LEADER_FAIL, GET_LEADER_REQUEST, GET_LEADER_SUCCESS } from "../types/leadersTypes";


export const addLeaderReducer = (state = {
    loading: false,
    lead: {}
  }, action) => {
    switch (action.type) {
      case ADD_LEADER_REQUEST:
        return { loading: true };
      case ADD_LEADER_SUCCESS:
        return { loading: false, lead: action.payload };
      case ADD_LEADER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export const getAllLeadersReducer = (state = {
    loading: false,
    allLeaders: []
  }, action) => {
    switch (action.type) {
      case GET_LEADER_REQUEST:
        return { loading: true };
      case GET_LEADER_SUCCESS:
        return { loading: false, allLeaders: action.payload };
      case GET_LEADER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  export const getAllApprovedLeadersReducer = (state = {
    loading: false,
    allLeaders: []
  }, action) => {
    switch (action.type) {
      case GET_LEADER_REQUEST:
        return { loading: true };
      case GET_LEADER_SUCCESS:
        return { loading: false, allLeaders: action.payload };
      case GET_LEADER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }