import React, { useEffect, useState } from "react";
import CommissionDisplay from "../../common/components/DashboardTab/CommisionDisplay";
import DualBarChartComponent from '../../common/components/DualBarChart';
import Calendar from 'react-calendar';
import '../../common/components/Calender.css';
import Bell from "../../data/Bell, Notifications.png";
import searchIcon from '../../data/Group 536.png';
import DashboardTab from "../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../common/components/GoodmorningBar";
import { FaCheck } from "react-icons/fa";
import { Button, Empty, Spin } from 'antd';
import CreateNewTask from '../../common/components/DashboardTab/CreateNewTask';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyDataAction, getAllTasksAction } from '../../redux/actions/dashboardAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../atom/authuserData/userdata';
import ShowCompanyModal from "../../common/components/DashboardTab/ShowCompanyModal";
import APIUrl from "../../atom/APIUrl";
import ConfirmationModal from "../../common/components/DashboardTab/ConfirmationModal";
import AllTaskModal from '../../common/components/DashboardTab/AllTaskModal';
import { axiosGet } from "../../redux/axios";
import axios from "axios";

const Dashboard = () => {
  const [date, setDate] = useState(new Date());
  const [alltasks, setAllTasks] = useState([]);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [companiesData, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [taskToConfirm, setTaskToConfirm] = useState(null);
  const [showAllTasksModal, setShowAllTasksModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const { role } = useSelector((state) => state.auth);
  const [counts, setCounts] = useState({
    commission: 0,
    leads: '...',
    deals: '...',
    tasks: '...',
  });

  const [operationsTeam, setOperationsTeam] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataOpList , setDataOpList] = useState([])
  const [commissionLoading, setCommissionLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchOperationDetails = async () => {
    try {
      const response = await axios.get(`${APIUrl}/deals/getOperationDetails`); // Replace with your API endpoint
      if (response.data.status === 1) {
        setOperationsTeam(response.data.data);
      } else {
        console.error("Failed to fetch operations team data.");
      }
    } catch (error) {
      console.error("Error fetching operations team data:", error);
    }
  };

  useEffect(() => {
    fetchOperationDetails();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      dispatch(getAllTasksAction(userId, date));
      fetchCountData(userId); // Fetch count data when userId changes
    }
  }, [userId, date, dispatch]);
  const { loading: allTaskLoading, allTasks } = useSelector(state => state.getAllTasks);
  const { loading: allcompaniesLoading, companies } = useSelector(state => state.fetchCompaniesData);

  
  useEffect(() => {
    setAllTasks(allTasks);
  }, [allTasks]);
  useEffect(() => {
    if (userId) {
      dispatch(getAllTasksAction(userId, date));
    }
  }, [userId, date, dispatch ]);

  const refreshTasks = () => {
    dispatch(getAllTasksAction(userId, date)); // Re-fetch tasks after creating a task
  };
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://bizfundapi.netlify.app/.netlify/functions/server/deals/getOperationDashboardDeals?user_id=${userId}`
        );
        const result = await response.json();
        if (result.status === 1) {
          setDataList(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeals();
  }, [userId]);

  useEffect(() => {
    const fetchOpDeals = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://bizfundapi.netlify.app/.netlify/functions/server/deals/getDealswithOpName?user_id=${userId}`
        );
        const result = await response.json();
        if (result.status === 1) {
          setDataOpList(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOpDeals();
  }, [userId]);

  const handleSelectTask = (id) => {
    const task = alltasks.find(task => task._id === id);

    if (!task.isdone) {
      setTaskId(id);
      setConfirmModalVisible(true);
    } else {
      alert('This task is already marked as done.');
    }
  };

  
  const confirmTask = async () => {
    try {
      setCommissionLoading(true);
      const response = await fetch(`${APIUrl}/tasks/markTaskDone?user_id=${userId}&task_id=${taskId}`, {
        method: 'GET',
      });
      
      if (!response.ok) {
        throw new Error('Failed to mark task as done');
      }
      
      dispatch(getAllTasksAction(userId, date));
      setConfirmModalVisible(false);
      setTaskId(null);
      fetchCountData(userId);
      
    } catch (error) {
      console.error('Error marking task as done:', error);
    }finally {
      setCommissionLoading(false); // Stop loading after task is confirmed
    }
  };
  
  const handleOpenModal = (deal) => {
    setSelectedDeal(deal);
    setShowModal(true);
  };
  
  useEffect(() => {
    dispatch(fetchCompanyDataAction(userId , role));
  }, [userId]);
  
  const handleSeeMore = () => {
    setShowAllTasksModal(true);
  };
  
  const fetchCountData = async (userId) => {
    try {
      // console.log("User::",userId)
      const response = await axiosGet(`dashboard/getCountData?user_id=${userId}`);
      // console.log("REsponse::",response)
      if (!response.status===200) {
        throw new Error('Failed to fetch count data');
      }
      const data = response.data;
      // Extract values from the response
      setCounts({
        commission: data.commisionCount,
        leads: data.leadCount,
        deals: data.dealCount,
        tasks: data.taskCount,
      });
      fetchCountData(userId);
    } catch (error) {
      console.error('Error fetching count data:', error);
    }
  };
  
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits:0
    }).format(amount);
  };

  const filteredCompanies = companies?.filter((company) =>
    company.company_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex">
      <DashboardTab />
      <main className="flex-1 bg-background-color py-6 w-[calc(100% - 278px)] px-7">
        <GoodMorningBar userName="Manish" />
        {allTaskLoading ?
         <div className='w-full h-screen pt-16 flex justify-center items-center' >
         <Spin />
         </div>
        :

        <div className="flex justify-between w-full gap-4">
          <div className='w-[75%]'>
            <div className="w-full flex bg-white mt-10 rounded-2xl p-10 space-x-6 py-2">
              {(role == 'agents') ? <CommissionDisplay loading={commissionLoading} amount={`$${formatAmount(counts.commission)}`} description="My commission this Week" /> : null}
              {(role == 'agents') ? <CommissionDisplay loading={commissionLoading} amount={counts.leads} description="Leads today" /> : <CommissionDisplay loading={commissionLoading} amount={counts.deals} description="My Deals" />}

              <CommissionDisplay loading={commissionLoading} amount={counts.tasks} description="Task to complete" />
            </div>
            <DualBarChartComponent className="mr-4 ml-6" />
            <div className="bg-white mt-6 rounded-2xl w-full">
           { role === 'opAdmin' ? 
           
           <div className="py-5 px-6 flex flex-col justify-between items-start w-full">
  <div className="text-green-text font-semibold text-base mb-4">
    Operation's Team
  </div>
  <div className="w-full max-h-80 overflow-y-auto border border-gray-300 rounded-lg shadow-sm bg-white">
    {operationsTeam?.map((member, index) => (
      <div
        key={index}
        className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-4 border-b last:border-none hover:bg-gray-50 transition"
      >
        <div className="flex flex-col">
          <div className="font-semibold text-gray-800">{member.name}</div>
          <div className="text-sm text-gray-600">{member.email}</div>
          <div className="text-sm text-gray-600">{member.phone}</div>
        </div>
        <div className="flex items-center">
          <span className="text-sm font-medium text-gray-500">Deals Reviewed:</span>
          <span className="ml-2 font-bold text-green-600">{member.dealsReviewed ?? '0'}</span>
        </div>
      </div>
    ))}
  </div>
            </div>
            : null}

              <div className="flex justify-between w-full px-6 gap-4">
                <div className='w-[50%]'>
                </div>
              </div>
              <ConfirmationModal
                visible={confirmModalVisible}
                onConfirm={confirmTask}
                onCancel={() => setConfirmModalVisible(false)}
              />
            </div>

            <div className="bg-white mt-6 rounded-2xl w-full">
            {role === 'opAdmin' ? (
    <div className="py-5 px-6 flex flex-col justify-between items-start w-full">
      <div className="text-green-text font-semibold text-base mb-4">Team's Claims</div>
      <div className="w-full max-h-80 overflow-y-auto border border-gray-300 rounded-lg shadow-sm bg-white">
        {dataOpList.length > 0 ? (
          dataOpList.map((data, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-4 border-b last:border-none hover:bg-gray-50 transition"
            >
              <div className="flex flex-col">
                <div className="font-semibold text-gray-800">{data.company_name}</div>
                <div className="text-sm text-gray-600">
                  Date: {new Date(data.createdAt).toLocaleDateString()}
                </div>
                <div className="text-sm text-gray-600">Email: {data.email}</div>
                <div className="text-sm text-gray-600">Phone: {data.phone}</div>
              </div>
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-500">Status:</span>
                <span className="ml-2 font-bold text-green-600">{data.dynamicStatus}</span>
              </div>
              <div className="flex flex-col">
              <div className="text-sm text-gray-600"><span className="font-bold">Agent: </span> {data.agent_name}</div>
              <div className="text-sm text-gray-600"><span className="font-bold">Operations: </span>{data.operation_person_name}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="py-5 text-center text-gray-600">No Data Available</div>
        )}
      </div>
    </div>
  ) : null}

</div>

            <div className="bg-white mt-6 rounded-2xl w-full">
            {role === "operations" || role === 'opAdmin' ? (
    <div className="py-5 px-6 flex flex-col justify-between items-start w-full">
      <div className="text-green-text font-semibold text-base mb-4">Your Claims</div>
      <div className="w-full max-h-80 overflow-y-auto border border-gray-300 rounded-lg shadow-sm bg-white">
        {dataList.length > 0 ? (
          dataList.map((data, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-4 border-b last:border-none hover:bg-gray-50 transition"
            >
              <div className="flex flex-col">
                <div className="font-semibold text-gray-800">{data.company_name}</div>
                <div className="text-sm text-gray-600">
                  Date: {new Date(data.createdAt).toLocaleDateString()}
                </div>
                <div className="text-sm text-gray-600">Email: {data.email}</div>
                <div className="text-sm text-gray-600">Phone: {data.phone}</div>
              </div>
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-500">Status:</span>
                <span className="ml-2 font-bold text-green-600">{data.dynamicStatus}</span>
              </div>
              <div className="text-sm text-gray-600">Agent: {data.agent_name}</div>
            </div>
          ))
        ) : (
          <div className="py-5 text-center text-gray-600">No Data Available</div>
        )}
      </div>
    </div>
  ) : null}

</div>

            <div className="bg-white mt-6 rounded-2xl w-full">
              <div className="py-5 px-6 flex justify-between items-center w-full">
                <div className='text-green-text font-semibold text-base'>
                  Today's tasks
                </div>
                <Button
                  className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
                  onClick={() => setShowCreateTaskModal(true)}
                >
                  Create new task
                </Button>
              </div>
              <div className="flex justify-between w-full px-6 gap-4">
                <div className='w-[50%]'>
                  <Calendar
                    onChange={setDate}
                    value={date}
                    className="custom-calendar"
                  />
                </div>
                <div className='w-[50%] h-[350px]'>
                  {allTaskLoading ? (
                    <div className='flex justify-center mt-5 w-full'>
                      <Spin />
                    </div>
                  ) : alltasks?.length === 0 ? (
                    <div className='w-full'>
                      <Empty description="No Tasks" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    alltasks?.slice(0, 4)?.map((t) => (
                      <div key={t._id} className={`w-full flex justify-between items-center py-2 px-2 rounded-2xl ${t.isdone === true ? 'bg-custom-green' : 'bg-background-color'} mb-2`}>
                        <div className="flex">
                          <div className="rounded-full h-10 w-10 bg-white p-1">
                            <img src={Bell} className="h-8 w-8" alt="Notification Bell" />
                          </div>
                          <div className={`pl-7 py-2 font-semibold text-sm ${t.isdone === true ? 'text-white' : 'text-green-text'}`}>
                            {t.task}
                          </div>
                        </div>
                        <button onClick={() => handleSelectTask(t._id)} className="bg-white rounded-full h-7 w-7 flex items-center justify-center">
                          {t.isdone === true && <FaCheck className='text-custom-green text-lg cursor-pointer' />}
                        </button>
                      </div>
                    ))
                  )}
                  {alltasks?.length > 4 && (
                    <div className='flex justify-center items-center w-full'>
                      <Button type='default' className='border-none text-custom-green' onClick={handleSeeMore}>
                        See more
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <ConfirmationModal
                visible={confirmModalVisible}
                onConfirm={confirmTask}
                onCancel={() => setConfirmModalVisible(false)}
              />
            </div>
            {showCreateTaskModal && (
              <CreateNewTask
                showCreateTaskModal={showCreateTaskModal}
                setShowCreateTaskModal={setShowCreateTaskModal}
                refreshTasks={refreshTasks}
              />
            )}
            {showAllTasksModal && (
              <AllTaskModal
                alltasks={alltasks}
                setAllTasks={setAllTasks}
                showAllTasksModal={showAllTasksModal}
                setShowAllTasksModal={setShowAllTasksModal}
                userId={userId}
                refreshTasks={refreshTasks}
              />
            )}
          </div>
          <div className="flex flex-col w-[25%] max-h-full bg-white mt-10 rounded-2xl py-8">
            <div className='w-full flex justify-center items-center'>
              <div className="w-[96%] flex items-center bg-background-color rounded-3xl h-10 px-4">
                <img src={searchIcon} alt="Search Icon" className="w-8 h-8 mr-2" />
                <input type="text" 
                placeholder="Search Merchants" 
                className="bg-background-color focus:outline-none h-10 pl-3" 
                value={searchQuery}
                onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="w-full mt-5 h-11 bg-background-color rounded-t-2xl pt-3 pl-7 text-gray-400 border-b border-gray-400">
            {(role == 'operations') || (role == 'opAdmin') ?  'Unclaimed Deals':'Companies'}
            </div>
            <div className='w-full'>
              {filteredCompanies?.length > 0 ? (
                filteredCompanies?.map((deal, index) => (
                  <div key={index} className="w-full flex justify-between items-center py-3 gap-[1rem] px-2 border-b border-gray-400">
                    <div className="pl-4 py-2 text-gray-500 text-sm">{deal.company_name}</div>
                    <div
                      className="bg-custom-green px-4 h-7 rounded-2xl text-white cursor-pointer"
                      onClick={() => handleOpenModal(deal)}
                    >
                      {role == 'operations'|| role == 'opAdmin' ?  'Claim':  'Open'}
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full text-center py-4 text-gray-500">No Companies Available</div>
              )}
            </div>
            {showModal && (
              <ShowCompanyModal
                visible={showModal} 
                onClose={() => setShowModal(false)}
                deal={selectedDeal}
              />
            )}
          </div>
        </div >
        }
      </main >
    </div >
  );
};

export default Dashboard;