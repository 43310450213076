import { axiosGet, axiosPost } from '../axios';
import {
  GET_ALL_COMMISION_FAIL,
  GET_ALL_COMMISION_REQUEST,
  GET_ALL_COMMISION_SUCCESS,
  GET_COMMISION_FAIL,
  GET_COMMISION_REQUEST,
  GET_COMMISION_SUCCESS,
  POST_REQUEST_COMMISION_FAIL,
  POST_REQUEST_COMMISION_REQUEST,
  POST_REQUEST_COMMISION_SUCCESS,
  GET_CONFIRM_PAID_SUCCESS,
  GET_CONFIRM_PAID_REQUEST,
  GET_CONFIRM_PAID_FAIL
} from '../types/commisionTypes';

export const getCommisionAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMMISION_REQUEST });
    console.log("USer::",userId)
    const { data } = await axiosGet(`commission/getCommission?user_id=${userId}`);
    // const { data } = await axiosGet(`commission/getCommission?user_id=66c32fe3054c581d6bcdf2ab`);

    dispatch({ type: GET_COMMISION_SUCCESS, payload: data?.details });
    return data?.data;
  } catch (error) {
    console.error("Error fetching commissions:", error);
    dispatch({
      type: GET_COMMISION_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
}

export const requestCommisionAction = (userId, commissionData) => async (dispatch) => {
  console.log("User for form:",userId)
  try {
    dispatch({ type: POST_REQUEST_COMMISION_REQUEST });

    const { data } = await axiosPost(`commission/requestCommision?user_id=${userId}`, commissionData);
    dispatch({ type: POST_REQUEST_COMMISION_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_REQUEST_COMMISION_FAIL,
      payload: error?.response?.data?.message,
    });
    return error.response?.data;
  }
}
export const getAllCommisionAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMMISION_REQUEST });

    console.log("User ID in request:", userId);

    const { data } = await axiosGet(`commission/getAllCommission?user_id=${userId}`);
    console.log("Data for API::",data)
    dispatch({ type: GET_ALL_COMMISION_SUCCESS, payload: data.details });
    return data?.data;
  } catch (error) {
    console.error("Error fetching commissions:", error);
    dispatch({
      type: GET_ALL_COMMISION_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
}


export const confirmPaidAction = (userId, lenderdealId , value) => async (dispatch) => {
  dispatch({ type: GET_CONFIRM_PAID_REQUEST });
  console.log("Va;ue API:",value)
  console.log("lenderDealId API:",lenderdealId)
  
  try {
    const response = await axiosGet(`commission/markPaid?user_id=${userId}&lenderdealId=${lenderdealId}&commisionStatus=${value}`);
    
    dispatch({
      type: GET_CONFIRM_PAID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONFIRM_PAID_FAIL,
      payload: error.message,
    });
  }
};