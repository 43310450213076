// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import ReusableTable from "../../../common/tables/Table";
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../data/Group 536.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLendersAction, getDeleteLenderAction } from '../../../redux/actions/lenderAction';
import moment from 'moment';
import { Spin ,Select } from 'antd';
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import DeleteLenderConfirmationModal from "../../../common/components/LenderComponent/DeleteLenderConfirmationModal";
import {TruncatedCell} from './TruncatedCell'
import FilterModal from './filterModal'; 
import defaultPhoto from './defaultImage.png'

const { Option } = Select;

const LenderDirectoryTable = () => {
  const [lenderTableData, setLenderTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { userId } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lenderId, setLenderId] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filterCategory, setFilterCategory] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const handleOpenFilterModal = () => setIsFilterModalVisible(true);
  const handleCloseFilterModal = () => setIsFilterModalVisible(false);

  const handleApplyFilter = (category, value) => {
    setFilterCategory(category);
    setFilterValue(value);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading: allLenderLoading, lenders } = useSelector(state => state.getAllLenders);
  const { loading: deleteLenderLoading } = useSelector(state => state.getDeleteLender);

  useEffect(() => {
    let filteredData = lenders || [];

    if (searchText) {
      const trimmedSearchText = searchText.trim();


      filteredData = filteredData.filter(lender =>
        lender?.lender_name?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
        lender?.owner_firstName?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
        lender?.owner_mail?.toLowerCase().includes(trimmedSearchText.toLowerCase())
      );
    }

    if (filterCategory && filterValue) {
      const items = filterValue.split(/[,|\u2022]/).map(item => item.trim()).filter(item => item); // Split filterValue
      filteredData = filteredData.filter(lender => {
        const lowerCaseRestrictedIndustry = lender?.restricted_industry?.toLowerCase();
        const lowerCaseRestrictedState = lender?.restricted_state?.toLowerCase();
        const lowerCasePreferredIndustry = lender?.prefered_industry?.toLowerCase() || "";
        
        return items.some(item => 
          (filterCategory === 'restrictedIndustry' && lowerCaseRestrictedIndustry?.includes(item.toLowerCase())) ||
          (filterCategory === 'restrictedState' && lowerCaseRestrictedState?.includes(item.toLowerCase())) ||
          (filterCategory === 'perferedIndustry' && lowerCasePreferredIndustry?.includes(item.toLowerCase()))
        );
      });
    }

    const lenderData = filteredData.map(lender => ({
      key: lender?._id,
      tier: lender?.tier || "-",
      lender: {
        profilePhoto: lender?.profile_photo || defaultPhoto,
        company: lender?.lender_name || "-",
        ceo: `${lender?.owner_firstName || ""} ${lender?.owner_lastName || ""}`,
        ceoFirstName: lender?.owner_firstName || "-",
        ceoLastName: lender?.owner_lastName || "-",
        ceoMail: lender?.owner_mail || "-"
      },
      maxfundingAmount: lender?.max_funding_amount || "-",
      monthlyMinimums: lender?.monthly_minimums || "-",
      daily: lender?.isDaily ? 'Yes' : 'No',
      weekly: lender?.isWeekly ? 'Yes' : 'No',
      biweeklyMonthly: lender?.isMonthly ? 'Yes' : 'No',
      nsf: lender?.nsf || "-",
      avgDailyBalance: lender?.avg_daily_balance || "-",
      positionToWilling: lender?.position_toWilling || "-",
      maximumPosition: lender?.max_position || "-",
      minimumPosition: lender?.min_position || "-",
      creditScoreMinimum: lender?.credit_score_min || "-",
      creditScoreMinimumValue: lender?.credit_score_min_value || "-",
      maxTerm: lender?.max_terms || "-",
      tib: lender?.tib || "-",
      restrictedIndustry: lender?.restricted_industry || "-",
      restrictedState: lender?.restricted_state || "-",
      perferedIndustry: lender?.prefered_industry || "-",
      deleteLender: { id: lender?._id },
      createdAt: lender?.createdAt,
    }));

    setLenderTableData(lenderData.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [lenders, searchText, filterCategory, filterValue]);

 

  useEffect(() => {
    dispatch(getAllLendersAction(userId));
  }, []);


  const showDeleteConfirmation = (lenderId) => {
    setLenderId(lenderId);
    setIsModalVisible(true);
  };


  const handleDeleteConfirm = () => {
    if (lenderId) {
      dispatch(getDeleteLenderAction(userId, lenderId))
        .then(() => {
          setIsModalVisible(false);
          dispatch(getAllLendersAction(userId));
          setLenderId(null);
        })
        .catch((error) => {
          console.error("Failed to delete lender:", error);
        });
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tier',
        accessor: 'tier',
        Cell: ({ value }) => (
          <span className="w-[30px] block">{value}</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Lender',
        accessor: 'lender',
        Cell: ({ value }) => (
          <div style={{display:'flex',flexDirection:'row',gap:'10px', alignItems:'center',justifyContent:'center'}}>
            <div style={{borderRadius: '50%', marginRight:'15px',width: '50px',alignContent:'center', justifyContent:'center', height: '50px'}}>

            <img
          src={value.profilePhoto}
          alt="Profile"
          />
          </div>
            <div className="w-[180px] text-good-morning-text font-semibold capitalize">{value.company}/{value.ceoFirstName}</div>
            {/* <div>{value.ceoMail}</div> */}
          </div>
        ),
      },
      {
        Header: 'Max Funding Amount',
        accessor: 'maxfundingAmount',
        Cell: ({ value }) => (
          <span className="w-[140px] block">
            {isNaN(value) ? "-":new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) }
            </span>
        ),
      },
      {
        Header: 'Monthly Minimums',
        accessor: 'monthlyMinimums',
        Cell: ({ value }) => (
          <span className="w-[130px] block">
            {isNaN(value) ? "-" :new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)}
          </span>
        ),
      },
      {
        Header: 'Daily',
        accessor: 'daily',
      },
      {
        Header: 'Weekly',
        accessor: 'weekly',
      },
      {
        Header: 'Bi-weekly/Monthly',
        accessor: 'biweeklyMonthly',
        Cell: ({ value }) => (
          <span className="w-[150px] block">{value}</span> // Adjust width as needed
        ),
      },
      {
        Header: 'NSF',
        accessor: 'nsf',
        Cell:({value})=>(
          <span className="w-[60px] block">{value}</span>
        )
      },
      {
        Header: 'Avg Daily Balance',
        accessor: 'avgDailyBalance',
        Cell: ({ value }) => (
          <span className="w-[150px] block">{isNaN(value) ? "-" : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)}
          </span>
        ),
      },
      {
        Header: 'Position Willing ',
        accessor: 'positionToWilling',
        Cell: ({ value }) => (
          <span className="w-[100px] block">{value ? value : "-"}</span>
        ),
      },
      // ----
      {
        Header: 'Maximum Position',
        accessor: 'maximumPosition',
        Cell: ({ value }) => (
          <span className="w-[130px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Minimum Position',
        accessor: 'minimumPosition',
        Cell: ({ value }) => (
          <span className="w-[130px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Credit Score Minimum',
        accessor: 'creditScoreMinimum',
        Cell: ({ value }) => (
          <span className="w-[150px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Credit Score Minimum Value',
        accessor: 'creditScoreMinimumValue',
        Cell: ({ value }) => (
          <span className="w-[200px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Max Term',
        accessor: 'maxTerm',
        Cell: ({ value }) => (
          <span className="w-[80px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'TIB',
        accessor: 'tib',
        Cell: ({ value }) => (
          <span className="w-[100px] block">{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Restricted Industry',
        accessor: 'restrictedIndustry',
        // Cell: ({ value }) => (
        //   <span>{value ? value : "-"}</span>
        // ),
        Cell: ({ value }) => (
          <div className="w-[160px] block">
            <TruncatedCell value={value ? `${value} / ${value}` : "-"} />
          </div>
        ),
      },
      {
        Header: 'Restricted State',
        accessor: 'restrictedState',
        Cell: ({ value }) => (
          <div className="w-[150px] block">
            <TruncatedCell value={value ? `${value} / ${value}` : "-"} />
          </div>
        ),
      },
      {
        Header: 'Perfered Industry',
        accessor: 'perferedIndustry',
        Cell: ({ value }) => (
          <div className="w-[150px] block">
          <TruncatedCell value={value ? `${value} / ${value}` : "-"} />
        </div>
        ),
      },
      ...(role === 'admin'
        ? [
            {
              Header: 'Delete Lender',
              accessor: 'deleteLender',
              Cell: ({ value }) => {
                const lenderId = value?.id;
                return (
                  <div className="flex gap-4">
                    <RiDeleteBinLine
                      alt="Delete Lender"
                      style={{ cursor: 'pointer', width: '100px', height: '20px' , marginRight: '40px' }}
                      onClick={() => {
                        showDeleteConfirmation(lenderId);
                      }}
                    />
                  </div>
                );
              },
            },
          ]
        : []),
        ...(role === 'admin'
          ? [
              {
                Header: 'Edit Lender',
                accessor: 'editLender',
                Cell: ({ value , row }) => {
                  const rowData = row.original;
                  const lenderId = value?.id;
                  return (
                    <div className="flex gap-4">
                      <FiEdit2
                        alt="Delete Lender"
                        style={{ cursor: 'pointer', width: '70px', height: '20px'  , marginRight: '40px'}}
                        onClick={() => {
                          navigate('/editLender', { state: { lenderDetails: rowData } });
                        }}
                      />
                    </div>
                  );
                },
              },
            ]
          : []),
    ],
    []
  );

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full w-[80vw] flex flex-col">
        <GoodMorningBar userName="Manish" />
        <div className="flex justify-between w-full mr-9 my-6">
          <div className="flex items-center">
            <div className="mt-3 ml-8 text-lender-green-text font-bold text-xl">
              Lenders
            </div>
          </div>
          <div className="flex gap-4">
            <button 
              onClick={handleOpenFilterModal} 
              className="mt-3 mr-8 h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer">
              Filter
            </button>
          <div onClick={() => navigate('/addLender')} className="mt-3 mr-8 h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer">
            Add new lender
          </div>
          </div>
        </div>

          

        <div className="flex-grow overflow-y-auto overflow-x-auto mx-4 mb-5">
          {allLenderLoading ?
            <div className='w-full pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
            <div className='mt-4'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search information"
                    className="bg-background-color focus:outline-none pl-3 w-full"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="overflow-x-auto"> */}
              <ReusableTable columns={columns} data={lenderTableData} />
              {/* </div> */}
            </div>
          }
        </div>
      </main>
      <FilterModal
        isVisible={isFilterModalVisible}
        onClose={handleCloseFilterModal}
        onApplyFilter={handleApplyFilter}
      />
      <DeleteLenderConfirmationModal
        visible={isModalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default LenderDirectoryTable;
