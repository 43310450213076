// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../data/Group 536.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmissionsAction, setShownOldDealsButton } from '../../../redux/actions/submissionBoardAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { Button, Select, Spin , DatePicker } from 'antd';
import moment from 'moment';
import OldDealsTable from './OldDealsTable';
import { DownOutlined } from '@ant-design/icons';
import { FaCircle } from 'react-icons/fa';
import { HorizontalFilter } from "../../../common/components/HorizontalFilter";

const { RangePicker } = DatePicker;

const SubmissionTable = () => {
  const [submissionsTableData, setSubmissionsTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [showOldDeals, setShowOldDeals] = useState(false);
  const [status, setStatus] = useState('');
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { role } = useSelector((state) => state.auth);
  const { loading: submissionsLoading, submissions } = useSelector(state => state.getAllSubmissions);
  const { showOldDeals: showOldDealsButton } = useSelector(state => state.setShownOldDealsButton);

  useEffect(() => {
    dispatch(getAllSubmissionsAction(userId));
  }, []);

  useEffect(() => {
    setShowOldDeals(showOldDealsButton);
  }, [showOldDealsButton]);

  const handleShowOldDeals = () => {
    setShowOldDeals(!showOldDeals);
    dispatch(setShownOldDealsButton(!showOldDeals));
  }

  useEffect(() => {
    let filteredData = []
    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = submissions.filter((submission) => {
        const agentName = `${submission?.firstName} ${submission?.lastName}`;
        return (
          submission?.agent_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.company_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.tag.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.lead_source.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          agentName.toLowerCase().includes(trimmedSearchText.toLowerCase())
        )
      });
    } else {
      filteredData = submissions;
    }

    if (status && status !== 'All') {
      filteredData = filteredData?.filter((deal) => deal.status === status);
    }

    if (dateRange?.length === 2) {
      const [startDate, endDate] = dateRange;
    
      // Convert start and end dates to moment objects in UTC
      const startMoment = (startDate);
      const endMoment = (endDate);

      console.log('Sending Starting and end Date  start Date ' , startMoment.format() , 'End Date ' , endMoment.format())
    
      filteredData = filteredData?.filter((deal) => {
        // Convert deal.createdAt to a moment object in UTC
        const dealDate = moment(deal?.createdAt).utc();
        console.log("Deal Date: ", dealDate?.toISOString());
    
        // Compare dealDate with the range [startMoment, endMoment]
        return dealDate.isBetween(startMoment?.format(), endMoment?.format(), null, '[]');  // '[]' includes the boundary dates
      });
    
      console.log("Filtered Data: ", filteredData);
    }

    const submissionData = []
    if (filteredData?.length > 0) {
      filteredData.forEach((submission) => {
        submissionData.push({
          key: submission?._id,
          status: submission?.status,
          agent: submission?.agent_name,
          company: submission?.company_name,
          date: submission?.createdAt,
          leadId: submission?.tag,
          leadSource: submission?.lead_source,
          lenders: 'Update Lenders',
          openDeal: submission,
          createdAt: submission?.createdAt,
        });
      });
    }

    setSubmissionsTableData(submissionData?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [submissions, searchText , status , dateRange]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = (dates[0]);
      const endDate = (dates[1]);
  
      console.log("Start Date: ", startDate, "End Date: " , endDate); 
      setDateRange([startDate, endDate]);
    } else {
      console.log("Invalid dates received, resetting range.");
      setDateRange([]);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
            <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
            <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({ value }) => (
          <span className="text-good-morning-text font-semibold">{value}</span>
        ),
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => (
          <span>{moment(value).format("MM/DD/YYYY")}</span>
        ),
      },
      {
        Header: 'Lead ID',
        accessor: 'leadId',
      },
      {
        Header: 'Lead Source',
        accessor: 'leadSource',
      },
      // {
      //   Header: 'Lenders',
      //   accessor: 'lenders',
      // },
      {
        Header:'Comments',
        accessor:'comment',
        Cell: ({ value }) => <span className="w-[150px] block">{value || 'No comment yet'}</span>,
      },

      {
        Header: 'Open deal',
        accessor: 'openDeal',
        Cell: ({ value }) => {<FiEye onClick={(() => { console.log("Open Lead Value ---- " , value) ;  navigate('/dealtabs', { state: { deal: value } }) })} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />}, // Render the eye icon
      },
    ],
    []
  );

  const options = [
    {
      label: (
        <span className="text-black flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          All
        </span>
      ),
      value: 'All',
    },
    {
      label: (
        <span className="text-green-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Approved
        </span>
      ),
      value: 'Approved',
    },
    {
      label: (
        <span className="text-orange-300 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Pending
        </span>
      ),
      value: 'Pending',
    },
    {
      label: (
        <span className="text-red-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Declined
        </span>
      ),
      value: 'Declined',
    },

    {
      label: (
        <span className="text-blue-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Contract Sent
        </span>
      ),
      value: 'Contract Sent',
    },
    {
      label: (
        <span className="text-yellow-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Funded
        </span>
      ),
      value: 'Funded',
    },
  ];

  const handleStatusChange = (value) => {
    console.log("Habndle hit === " , value)
    setStatus(value);
  }

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        {(role === "admin" || role === "opAdmin" ) &&
          <div className='flex justify-end items-center gap-2 mt-3'>
            {/* {showOldDeals &&
              <Select
                size='medium'
                allowClear
                style={{ width: "150px", height: "36px" }}
                options={options}
                placeholder="Select status"
                suffixIcon={<DownOutlined />}
                onChange={e => handleStatusChange(e)}
              />
            } */}
            <Button
              onClick={() => handleShowOldDeals()}
              className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
            >
              {showOldDeals ? 'Hide old deals' : 'Show old deals'}
            </Button>
          </div>
        }
        {showOldDeals ? <OldDealsTable status={status} /> :
          <div className="flex-grow overflow-y-auto mx-4 mb-5">
            {submissionsLoading ?
              <div className='w-full pt-16 flex justify-center items-center' >
                <Spin />
              </div>
              :
              <div className='mt-4'>
                <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex flex-row justify-center items-center">
                  <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                      className="w-8 h-8 mr-2"
                    />
                    <input
                      type="text"
                      placeholder="Search information"
                      className="bg-background-color focus:outline-none pl-3 w-full"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <RangePicker
  className="text-black font-bold"
  onChange={(dates) => handleDateChange(dates)}
/>
                  </div>
                  <HorizontalFilter options={options} onStatusChange={handleStatusChange} />
                </div>
                <ReusableTable columns={columns} data={submissionsTableData} />
              </div>
            }
          </div>
        }
      </main>
    </div>
  );
};

export default SubmissionTable;
