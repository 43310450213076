import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, List, Spin , Modal } from 'antd';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import PdfPreviewModal from './PdfPreviewModal';
import { axiosGet } from '../../../../../redux/axios';

const DocumentList = ({ allFilesData ,setAllFilesData }) => {
    const [showPdfPreviewModal, setShowPdfPreviewModal] = useState(false);
    const [selectedPdfFile, setSelectedPdfFile] = useState(null);

    const { loading: allFilesLoading } = useSelector(state => state.getAllFiles);

    const handlePreview = (fileUrl) => {
        setShowPdfPreviewModal(true)
        setSelectedPdfFile(fileUrl)
    }

    const confirmDelete = (fileId) =>{
        Modal.confirm({
            title: 'Are you sure you want to delete this file?',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => handleDelete(fileId),
        });
    };


    const handleDelete  = async (fileId)=>{
        console.log("File::",fileId)
        try{
            await axiosGet(`leads/deleteFiles?fileId=${fileId}`);
            alert("File Deleted Successfully")
            setAllFilesData(prev=>prev.filter(file=>file.fileId !== fileId));
        }catch(error){
            alert("Failed to Delete file")
            console.log(error)
        }
    }

    return (
        <div
            id="scrollableDiv"
            style={{
                height: 400,
                width: '100%',
                overflow: 'auto',
                padding: '0 16px',
                // border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <div className='flex justify-between bg-[#f7f7f7] h-10 items-center px-4 rounded-tl-lg rounded-tr-lg'>
                <h2>File name</h2>
                <h2>preview</h2>
            </div>
            <Spin spinning={allFilesLoading}>
                <List
                    dataSource={allFilesData}
                    renderItem={(item, index) => (
                        <List.Item key={index}>
                            <List.Item.Meta
                                title={<h1>{item?.title}.pdf</h1>}
                                description={`Uploaded ${moment(item?.uploadedAt).format("MMM DD, YYYY")}`}
                            />
                            <div className='flex gap-4'>
                                <Button
                                    onClick={() => handlePreview(item?.fileUrl)}
                                >
                                    <EyeOutlined />
                                </Button>
                                <Button
                                    type="default"
                                    danger
                                    onClick={()=>confirmDelete(item?.fileId)}
                                >
                                    Delete
                                </Button>
                            </div>
                        </List.Item>
                    )}
                />
            </Spin>
            {showPdfPreviewModal && selectedPdfFile &&
                <PdfPreviewModal
                    visible={showPdfPreviewModal}
                    onCancel={() => setShowPdfPreviewModal(false)}
                    pdf={selectedPdfFile}
                />
            }
        </div>
    );
}

export default DocumentList