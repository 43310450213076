import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import Router from './routes/Router';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

function App() {
  return (
    <Provider store={store}>
    <BrowserRouter>
      <div className="App">
        <Router/>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
      />
      </div>
      {/* ----------- */}
    </BrowserRouter>
    </Provider>
  );
}

export default App;