import { atom, useRecoilState } from "recoil";
import APIUrl from "../APIUrl";
import { setUser } from "../../redux/actions/authAction";
import store from "../../redux/store";

export const isLoading = atom({
	key: "loading",
	default: false,
});

export const userIdAtom = atom({
	key: "user_id",
	default: null,
});

export const userName = atom({
	key: "user_name",
	default: "",
});

export const loginUser = async (email, password) => {
    
    try {
        const response = await fetch(`${APIUrl}/users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const data = await response.json();

        console.log("data of login API --- " , data)

        if (data.status === 1) {
            const sessionData = {
                token: data.token,
                role: data.role,
                userId: data.userid,
                name: data.firstName,
                expiry: new Date().getTime() + 15 * 60 * 1000 
            };
            localStorage.setItem('sessionData', JSON.stringify(sessionData)); 

            console.log('Session Data --- ' , sessionData)
            if (sessionData) {
                store.dispatch(setUser({
                    userId: sessionData.userId,
                    name: sessionData.name,
                    role: sessionData.role,
                }));
            }
            
            console.log("data --------- huy == " , data )
            return data;
        } else {
            
          alert(data.message || "Login failed");
          return data;
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const signupMail = async (email, name , role , user_id) => {
    console.log("UserId ----- " , user_id  , "  Email -- " ,email , "  name " , name , " role " , role)

    try {
        const response = await fetch(`${APIUrl}/users/signupMail?user_id=${user_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name,
                role: role
            }),
        });


        const data = await response.json();

        if (data.status === 1) {
            console.log("data --------- huy == " , data )
            return data;
        } else {
            return data;
          alert(data.message || "Login failed");
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const createPasswordNewrol = async (password , user_id) => {
    console.log("UserId ----- " , user_id  ,  "Password ----- " , password)
    try {
        const response = await fetch(`${APIUrl}/users/createPassword?user_id=${user_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password
            }),
        });
        const data = await response.json();
        console.log(" create Password Data ---------  == " , data )
        if (data.status === 1) {
            console.log(" create Password Data ---------  == " , data )
            return data;
        } else 
            return data;
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const sendOTP = async (email) => {
    console.log("Send OTP hit")
    try {
        const response = await fetch(`${APIUrl}/users/sendOTP?email=${email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     email: email,
            //     password: password,
            // }),
        });

        const data = await response.json();

        console.log("data of login API --- " , data)

        if (data.status === 1) {
            const sessionData = {
                token: data.token,
                role: data.role,
                userId: data.userid,
                name: data.firstName,
                expiry: new Date().getTime() + 15 * 60 * 1000 
            };
            localStorage.setItem('sessionData', JSON.stringify(sessionData)); 

            console.log('Session Data --- ' , sessionData)
            if (sessionData) {
                store.dispatch(setUser({
                    userId: sessionData.userId,
                    name: sessionData.name,
                    role: sessionData.role,
                }));
            }
            
            console.log("data --------- huy == " , data )
            return data;
        } else {
            
          alert(data.message || "Login failed");
          return data;
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const resetPassword = async (email , otp , password) => {
    console.log("Reset Password hit")
    try {
        const response = await fetch(`${APIUrl}/users/resetPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                otp: otp,
                newPass: password
            }),
        });

        const data = await response.json();

        console.log("data of login API --- " , data)

        if (data.status === 1) {
            return data;
        } else {
            
          alert(data.message );
          return data;
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        alert(error );
        throw error;
    }
};


// export const editLender = async (userId, lender_id, data) => {
//     console.log('Edit Lender data ' , data);
//     try {
//       const response = await fetch(`${APIUrl}/lenders/edit/${lender_id}`, {
//         method: "POST", // Use PUT or PATCH for updates
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`, // Add authorization token if required
//         },
//         body: data, // Pass FormData directly without setting Content-Type
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to update lender. Status: ${response.status}`);
//       }
  
//       const responseData = await response.json();
//       return responseData;
//     } catch (error) {
//       console.error("Error in editLender API:", error);
//       throw error;
//     }
//   };
  

