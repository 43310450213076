import React, { useState } from 'react';
import { Modal, Button, Row, Col, Divider } from 'antd';
import { claimDeal } from '../../../atom/dashboardData/dealsApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ShowCompanyModal = ({ visible, onClose, deal }) => {
  const { userId } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  console.log("Deals --- " , deal);

  const handleClaim = async () => {
    try {
      setLoading(true); // Show loading
      const result = await claimDeal(deal?._id, userId);

      if (result.status === 1) {
        onClose(); // Close the modal
        setTimeout(() => {
          window.location.reload(); // Reload the page after closing the modal
        }, 100); // Add a small delay to ensure modal closes smoothly
      }
    } catch (error) {
      console.error("Error claiming deal:", error);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  return (
    <Modal
      title="Company Details"
      visible={visible}
      onCancel={onClose}
      footer={null} // No default footer
      width={600}
    >
      {deal ? (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p><strong>City:</strong> {deal.city || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Company Name:</strong> {deal.company_name || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Created At:</strong> {deal.createdAt || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Email:</strong> {deal.email || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>First Name:</strong> {deal.firstName || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Last Name:</strong> {deal.lastName || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Phone:</strong> {deal.phone || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Status:</strong> {deal.status || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>Queue:</strong> {deal.queue || 'N/A'}</p>
            </Col>
            <Col span={12}>
              <p><strong>User ID:</strong> {deal.user_id || 'N/A'}</p>
            </Col>
            <Col span={24}>
              <p><strong>Lead Source:</strong> {deal.lead_source || 'N/A'}</p>
            </Col>
          </Row>
          <Divider />
          <div style={{ textAlign: 'center', marginTop: 16  }}>
          <Button
              style={{ backgroundColor: 'green', borderColor: 'green' }}
              type="primary"
              loading={loading} // Show loading spinner
              onClick={handleClaim}
            >
              Confirm Claim
            </Button>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>No details available</p>
      )}
    </Modal>
  );
};

export default ShowCompanyModal;
