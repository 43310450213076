import {
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS
} from '../types/userTypes';

export const getUserDetailsReducer = (state = {
  loading: false,
  user: {}
}, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return { loading: true };
    case GET_USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case GET_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const updateUserDetailsReducer = (state = {
  loading: false,
  updateUser: {}
}, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS_REQUEST:
      return { loading: true };
    case UPDATE_USER_DETAILS_SUCCESS:
      return { loading: false, updateUser: action.payload };
    case UPDATE_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}