import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../data/Group 557.png'; 
import logo from '../data/Group 558.png'; 
import AuthTab from '../common/authTab/authTab';
import googleImg from '../data/google.png'; 
import GreenDotWithText from '../common/components/loginInstruction';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; 
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import { BiSolidToggleLeft, BiSolidToggleRight } from "react-icons/bi";
import { SET_ROLE } from '../redux/types/authTypes'; // Ensure the correct path to the action file
import { loginUser, userIdAtom, userName } from '../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';
import { setSelectedTab } from '../redux/actions/authAction';
import { setShownOldDealsButton } from '../redux/actions/submissionBoardAction';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState('Log in');
  const menuItems = ['Log in'];
  const { role } = useSelector((state) => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [userId  , setuserId] = useRecoilState(userIdAtom);
  const [name  , setName] = useRecoilState(userName);
  const [isLoading  , setIsloading] = useState(false);

  useEffect(() => {
    const checkSession = () => {
      const sessionData = JSON.parse(localStorage.getItem('sessionData'));
      if (sessionData) {
        const currentTime = new Date().getTime();
        if (currentTime >= sessionData.expiry) {
          alert('Session expired. Please log in again.');
          localStorage.removeItem('sessionData');
          navigate('/'); // Redirect to login page
        }
      }
    };
  
    const sessionInterval = setInterval(checkSession, 1000); // Check every second
  
    return () => clearInterval(sessionInterval); // Cleanup on component unmount
  }, [navigate]);
  

  const handleSelect = (label) => {
    setSelected(label);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleButton = () => {
    setToggleBtn(!toggleBtn);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // if (e.target.value.includes('admin')) {
    //   dispatch({ type: SET_ROLE, payload: 'admin' });
    // } else if (e.target.value.includes('agent')) {
    //   dispatch({ type: SET_ROLE, payload: 'agent' });
    // } else if (e.target.value.includes('operations')) {
    //   dispatch({ type: SET_ROLE, payload: 'operations' });
    // } else {
    //   dispatch({ type: SET_ROLE, payload: 'agent' });
    // }
  };

  const handleSubmit  = async (e) => {

    e.preventDefault();
    if (email && password) {
        setIsloading(true);
        const data =  await loginUser(email , password);
        if(data.status == 1){
          console.log("Data after login " , data)
          setuserId(data.userid)
          setName(data.firstName)
          dispatch({ type: SET_ROLE, payload: data.role });
          dispatch(setShownOldDealsButton(false));
          navigate(data.role === 'admin' ? '/business-analytics' : '/dashboard');
          dispatch(setSelectedTab(data.role === 'admin' ? 'Business Analytics' : 'Dashboard'));
          setIsloading(false);
        }
        else{
          setIsloading(false);
        }
        console.log("data ----------------- " , data)
        // navigate(role === 'admin' ? '/business-analytics' : '/dashboard');
       
    } else {
      alert('Please fill in both email and password fields.');
    }
  };

  return (
    <div className="flex h-screen bg-background-color">
      <div className="w-2/5 flex items-center justify-between" style={{ backgroundColor: '#00A762' }}>
        <img 
          src={backgroundImage} 
          alt="Background" 
          className="object-cover h-full w-auto"
        />
      </div>

      <div className="w-1/2 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-background-color rounded-lg align-middle">
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="w-auto h-auto" />
          </div>

          {selected === 'Sign up' ? (
            <div>
              <h1 className="text-2xl font-bold text-center text-custom-green text-4xl">Getting started</h1>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder='olivia@fidifunding.com'
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <IoLockClosedOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      ) : (
                        <IoLockOpenOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      )}
                    </span>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'password' : 'text'}
                      autoComplete="current-password"
                      placeholder="************"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                    />
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <AiOutlineEyeInvisible style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      ) : (
                        <AiOutlineEye style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <div className='flex'>
                      <GreenDotWithText text="At least one letter" />
                      <GreenDotWithText text="At least 8 characters" />
                    </div>
                    <GreenDotWithText text="At least one number" />
                  </div>
                  <div className='flex items-center gap-4'>
                    <div>
                      {toggleBtn ? (
                        <BiSolidToggleRight onClick={toggleButton} className='text-custom-green text-4xl cursor-pointer' /> 
                      ) : (
                        <BiSolidToggleLeft onClick={toggleButton} className='text-slate-300 text-4xl cursor-pointer' />
                      )}
                    </div>
                    <div className={`${toggleBtn ? 'text-custom-green font-normal text-m' : 'text-slate-500 font-normal text-m'}`}>
                      I agree to the <span className='font-medium'>Terms & Conditions</span>
                    </div>
                  </div>
                  {/* <div> */}
                  <button
                  onClick={() => {}}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4"
                    style={{ backgroundColor: '#00A762' }}
                  >
                    Sign up
                  </button>
                  {/* </div> */}
                  <div className="flex justify-center mt-4">
                    Already have an account? <span className='text-custom-green font-bold ml-1'>Sign in</span>
                  </div>
                </div>
                <div className="border-t-2 border-green-500 mt-2 w-36 mx-auto"></div>
                <button
                  style={{color: '#0A2759'}}
                  type="submit"
                  className="w-full flex justify-center gap-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-google-button-color hover:bg-google-button-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green mt-8"
                >
                  <img src={googleImg} alt="googleIcon" /> Sign up with Google
                </button>
              </form>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl font-bold text-center text-custom-green text-4xl">Welcome again</h1>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder='olivia@fidifunding.com'
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <IoLockClosedOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      ) : (
                        <IoLockOpenOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      )}
                    </span>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'password' : 'text'}
                      autoComplete="current-password"
                      placeholder="************"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                    />
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <AiOutlineEyeInvisible style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      ) : (
                        <AiOutlineEye style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-8"
                    style={{ backgroundColor: '#00A762' }}
                  >
                   { isLoading ?'Loading...' :'Log in'}
                  </button>
                </div>
                {/* <div className="flex justify-center mt-4">
                  Already have an account? <span className='text-custom-green font-bold ml-1'>Sign in</span>
                </div> */}
                <div className="flex justify-center mt-4">
                  Forgot password? <span onClick={() => navigate('/forgotPassword')}  className='text-custom-green font-bold ml-1 cursor-pointer'>Reset Password</span>
                </div>
                <div className="border-t-2 border-green-500 mt-2 w-36 mx-auto"></div>
                <button
                  style={{color: '#0A2759'}}
                  type="submit"
                  className="w-full flex justify-center gap-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-google-button-color hover:bg-google-button-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green mt-8"
                >
                  <img src={googleImg} alt="googleIcon" /> Sign up with Google
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
