import { SET_CHILD_TAB, SET_ROLE, SET_SELECTED_TAB , SET_USER } from '../types/authTypes';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setSelectedTab = (tab) => ({
  type: SET_SELECTED_TAB,
  payload: tab,
});

export const setChildSelectedTab = (childtab) => ({
  type: SET_CHILD_TAB,
  payload: childtab,
});

export const setRole = (role) => ({
  type: SET_ROLE,
  payload: role,
});

export const logout = () => {
  localStorage.removeItem('sessionData');
  return {
    type: SET_USER,
    payload: {
      userId: null,
      name: null,
      role: 'agent',
    },
  };
};