import { DELETE_LEAD_FAIL, DELETE_LEAD_REQUEST, DELETE_LEAD_SUCCESS, GET_QUEUED_LEADS_FAIL, GET_QUEUED_LEADS_REQUEST, GET_QUEUED_LEADS_SUCCESS, GET_REMOVED_LEADS_FAIL, GET_REMOVED_LEADS_REQUEST, GET_REMOVED_LEADS_SUCCESS, POST_COMPLIANCE_FORM_FAIL, POST_COMPLIANCE_FORM_REQUEST, POST_COMPLIANCE_FORM_SUCCESS, RESTORE_LEAD_FAIL, RESTORE_LEAD_REQUEST, RESTORE_LEAD_SUCCESS } from '../types/complianceTypes';

export const postComplianceFormReducer = (state = {
  loading: false,
  postCompliance: {},
}, action) => {
  switch (action.type) {
    case POST_COMPLIANCE_FORM_REQUEST:
      return { loading: true };
    case POST_COMPLIANCE_FORM_SUCCESS:
      return { loading: false, success: true, postCompliance: action.payload };
    case POST_COMPLIANCE_FORM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getQueuedLeadsReducer = (state = {
  loading: false,
  queuedLeads: [],
}, action) => {
  switch (action.type) {
    case GET_QUEUED_LEADS_REQUEST:
      return { loading: true };
    case GET_QUEUED_LEADS_SUCCESS:
      return { loading: false, success: true, queuedLeads: action.payload };
    case GET_QUEUED_LEADS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getRemovedLeadsReducer = (state = {
  loading: false,
  removedLeads: [],
}, action) => {
  switch (action.type) {
    case GET_REMOVED_LEADS_REQUEST:
      return { loading: true };
    case GET_REMOVED_LEADS_SUCCESS:
      return { loading: false, success: true, removedLeads: action.payload };
    case GET_REMOVED_LEADS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const restoreLeadReducer = (state = {
  loading: false,
  restoreLead: {},
}, action) => {
  switch (action.type) {
    case RESTORE_LEAD_REQUEST:
      return { loading: true };
    case RESTORE_LEAD_SUCCESS:
      return { loading: false, success: true, restoreLead: action.payload };
    case RESTORE_LEAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const deleteLeadReducer = (state = {
  loading: false,
  deleteLead: {},
}, action) => {
  switch (action.type) {
    case DELETE_LEAD_REQUEST:
      return { loading: true };
    case DELETE_LEAD_SUCCESS:
      return { loading: false, success: true, deleteLead: action.payload };
    case DELETE_LEAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}