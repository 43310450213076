export const POST_COMPLIANCE_FORM_REQUEST = 'POST_COMPLIANCE_FORM_REQUEST';
export const POST_COMPLIANCE_FORM_SUCCESS = 'POST_COMPLIANCE_FORM_SUCCESS';
export const POST_COMPLIANCE_FORM_FAIL = 'POST_COMPLIANCE_FORM_FAIL';

export const GET_QUEUED_LEADS_REQUEST = 'GET_QUEUED_LEADS_REQUEST';
export const GET_QUEUED_LEADS_SUCCESS = 'GET_QUEUED_LEADS_SUCCESS';
export const GET_QUEUED_LEADS_FAIL = 'GET_QUEUED_LEADS_FAIL';

export const GET_REMOVED_LEADS_REQUEST = 'GET_REMOVED_LEADS_REQUEST';
export const GET_REMOVED_LEADS_SUCCESS = 'GET_REMOVED_LEADS_SUCCESS';
export const GET_REMOVED_LEADS_FAIL = 'GET_REMOVED_LEADS_FAIL';

export const RESTORE_LEAD_REQUEST = 'RESTORE_LEAD_REQUEST';
export const RESTORE_LEAD_SUCCESS = 'RESTORE_LEAD_SUCCESS';
export const RESTORE_LEAD_FAIL = 'RESTORE_LEAD_FAIL';

export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAIL = 'DELETE_LEAD_FAIL';