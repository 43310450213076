import React from 'react';
import { Modal, Button } from 'antd';

const ConfirmationModal = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
      title="Confirm Task Completion"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} className='bg-white text-green-text'>
          No
        </Button>,
        <Button key="confirm" onClick={onConfirm} className='bg-custom-green text-white'>
          Yes
        </Button>
      ]}
    >
      <p>Are you sure you want to mark this task as done?</p>
    </Modal>
  );
};

export default ConfirmationModal;



