export const GET_COMMISION_REQUEST = 'GET_COMMISION_REQUEST';
export const GET_COMMISION_SUCCESS = 'GET_COMMISION_SUCCESS';
export const GET_COMMISION_FAIL = 'GET_COMMISION_FAIL';

export const POST_REQUEST_COMMISION_REQUEST = 'POST_REQUEST_COMMISION_REQUEST';
export const POST_REQUEST_COMMISION_SUCCESS = 'POST_REQUEST_COMMISION_SUCCESS';
export const POST_REQUEST_COMMISION_FAIL = 'POST_REQUEST_COMMISION_FAIL';


export const GET_ALL_COMMISION_REQUEST = 'POST_REQUEST_COMMISION_REQUEST';
export const GET_ALL_COMMISION_SUCCESS = 'POST_REQUEST_COMMISION_SUCCESS';
export const GET_ALL_COMMISION_FAIL = 'POST_REQUEST_COMMISION_FAIL';


export const GET_CONFIRM_PAID_SUCCESS ='CONFIRM_PAID_SUCCESS';
export const GET_CONFIRM_PAID_REQUEST ='CONFIRM_PAID_REQUEST';
export const GET_CONFIRM_PAID_FAIL ='CONFIRM_PAID_FAIL';



