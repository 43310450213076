// Dashboard.js
import React, { useEffect, useRef, useState } from "react";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OrgChart = () => {

  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const [selectedUser, setSelectedUser] = useState(null);
  const [viewAll, setViewAll] = useState(true);
  const [isRightTabOpen, setIsRightTabOpen] = useState(false);

  const menuItems = [
    "Dashboard",
    "Leads",
    "Deals",
    "Submission Board",
    "Lender directory",
    "Compliance",
    "Commision portal",
  ];

  const users = [
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { initials: "KS", name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { initials: "RV", name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    // Add more users...
  ];

  const rightData = [
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { initials: "AS", name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
    { name: "Kamal Singh", percentage: "50%", commission: "5,673" },
    { name: "Ram Verma", percentage: "60%", commission: "6,500" },
    { name: "Archna Sharma", percentage: "45%", commission: "4,890" },
  ]
  
  const handleCardClick = (user) => {
    setSelectedUser(user);
    setIsRightTabOpen(true);
  };
  const closeRightTab = () => {
    setIsRightTabOpen(false);
  };


  const widthClass = isRightTabOpen ? "max-w-[67%]" : "max-w-[100%]";
  const items = role === "admin" 
    ? ["Account Settings", "Org Chart", "Add new role", "Set funding target"] 
    : ["Account Settings", "Compliance Settings", "Email templates settings", "Sequences Settings"];
  return (
    <div className="flex h-screen bg-black">
      <div className="flex space-x-24 w-80">
        <SecondaryNavigation items={menuItems} />
        <ChildNavigation items={items} label={'Settings'} />
      </div>
      <main className="flex-1 bg-background-color p-6 pl-12 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className={`flex justify-center ${widthClass} mr-9 my-6 `}>
            <div className="flex flex-row justify-center gap-5 px-10 py-5 bg-white rounded-md shadow">
              <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPgwYYhHGntkkaWUE7g3jGjMkqSkSYbGq4g&s"
                  alt="User Avatar"
                  className="w-20 h-20 rounded-full border-2 border-gray-300"
                />
              <div>
                <p className="text-[#0A2759] font-semibold">Name of Admin</p>
                <p className="text-[#0A2759]">Percentage: 50%</p>
                <p className="text-[#0A2759]">Total Commissions: 5,673</p>
              </div>
            </div>
        </div>
        <div
          className={`flex flex-wrap gap-16 justify-left p-6 bg-gray-100 max-h-[65vh] ${widthClass} overflow-y-scroll`}
        >
          {users.map((user, index) => (
            <div
              key={index}
              className="flex flex-col items-center relative group cursor-pointer"
              onClick={() => handleCardClick(user)}
            >
              {/* Card */}
              <div className="w-24 h-24 bg-[#007645] text-white rounded-full flex items-center justify-center">
                <span className="text-xl font-bold">{user.initials}</span>
              </div>
              {/* Name Below the Card */}
              <p className="text-center text-sm font-medium bg-white px-2 text-[#004629] mt-[-6px]">
                {user.name}
              </p>
              {/* Hover Details */}
              <div className="absolute top-[80%] w-[12vw] bg-white p-2 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity">
                <p className="text-center text-sm font-medium text-[#004629]">{user.name}</p>
                <p className="text-center text-sm text-[#004629]">Percentage: {user.percentage}</p>
                <p className="text-center text-sm text-[#004629]">Commission: ${user.commission}</p>
              </div>
            </div>
          ))}
        {/* Right Tab */}
        {isRightTabOpen && (
          <div className="absolute top-[10%] right-0 w-1/4 h-[90%] bg-white shadow-lg p-6 flex flex-col overflow-y-auto" >
          <button
      onClick={closeRightTab}
      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
          <h3 className="text-xl font-semibold text-[#0A2759] mb-4">Agents</h3>
          {rightData.map((agent, index) => (
            <div
              key={index}
              className="flex flex-col rounded-md p-2 border-2 shadow mb-4"
            >
              <div className="flex items-center">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPgwYYhHGntkkaWUE7g3jGjMkqSkSYbGq4g&s"
                  className="w-20 h-20 mr-5 border-2 border-gray-300"
                />
                <div>
                  <p className="text-[#0A2759] font-semibold">{agent.name}</p>
                  <p className="text-sm text-gray-600">
                    Percentage: {agent.percentage}
                  </p>
                  <p className="text-sm text-gray-600">
                    Commission: ${agent.commission}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        )}
        </div>
      </main>
    </div>
  );
};

export default OrgChart;
