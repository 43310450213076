import React, { useEffect, useState } from 'react';
import { BiSolidUpArrow } from "react-icons/bi";
import { getAdminSubmissionDataAction } from '../../redux/actions/dashboardAction';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

const AdminGrid = () => {
  const [adminSubmissionData, setAdminSubmissionData] = useState({})

  const dispatch = useDispatch();
  const { loading: submissionDataLoading, submissionData } = useSelector(state => state.getAdminSubmissionData);

  useEffect(() => {
    dispatch(getAdminSubmissionDataAction())
  }, []);

  useEffect(() => {
    if (submissionData) {
      setAdminSubmissionData(submissionData)
    }
  }, [submissionData]);

  return (
    <div>
    {submissionDataLoading?
     <div className='w-full h-screen pt-16 flex justify-center items-center' >
     <Spin />
   </div>
   :
    // <Spin spinning={submissionDataLoading}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
        {Object.keys(adminSubmissionData)?.map((key) => (
          <div key={key} className="bg-white   pt-3 rounded-xl shadow w-[14vw] text-center">
            <div className="text-l font-semibold text-lender-green-text  mb-1">{key === "totalSubmission" ? "Total submission" : key === "dealApproved" ? "Total deals approved" : key === "dealDeclined" ? "Total deals declined" : key === "approvalRatio" ? "Approval ratio %" : key === "declineRatio" ? "Decline ratio %" : key === "fundingRatio" ? "Funding ratio %" : ""}</div>
            <div className={`${key === 'dealDeclined' || key === 'declineRatio' ? "text-red-700" : "text-custom-green"} text-4xl font-semibold`}>
              {adminSubmissionData[key]?.thisMonth?.toFixed(0)}{key?.includes('Ratio') ? '%' : ''}
            </div> <div className="flex justify-between">
              <div className='bg-gray-300 w-full mt-3 py-2 text-good-morning-text font-semibold text-sm text-left px-3'>
                Last Month
              </div>
            </div>
            <div className='flex justify-between py-3 px-5'>
              <div className='text-good-morning-text font-semibold text-2xl'>{adminSubmissionData[key]?.prevMonth?.toFixed(0)}{key?.includes('Ratio') ? '%' : ''}</div>
              <div className='flex items-center'>
                <BiSolidUpArrow color={`${adminSubmissionData[key]?.thisMonth > adminSubmissionData[key]?.prevMonth ? '#00A762' : 'red'}`} className='mr-3' />
                <div className={`${adminSubmissionData[key]?.thisMonth > adminSubmissionData[key]?.prevMonth ? 'text-custom-green' : 'text-red-700'} font-semibold text-2xl`}>{(adminSubmissionData[key].thisMonth - adminSubmissionData[key].prevMonth).toFixed(0)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      }
    {/* </Spin> */}
     </div>
  );
};

export default AdminGrid;
