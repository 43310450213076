import { useEffect, useState } from 'react';
import searchIcon from '../../../data/Group 536.png';
import { Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getOldLenderByDealIdAction } from '../../../redux/actions/dealsAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { render } from '@testing-library/react';

const OldDealLenderTable = ({ user_id }) => {
  const [searchText, setSearchText] = useState('');
  const [oldLenderTableData, setOldLenderTableData] = useState([]);
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: oldLenderLoading, oldLender } = useSelector(state => state.getOldLenderByDealId);

  useEffect(() => {
    dispatch(getOldLenderByDealIdAction(userId, user_id));
  }, []);

  useEffect(()=>{
    console.log("Lenders:",oldLender)
  },[oldLender])

  useEffect(() => {
    let filteredData = []
    oldLender?.forEach((lender) => {
      filteredData.push({
        key: lender?._id,
        lenderName: lender?.lender_name,
        status: lender?.status,
        comment: lender?.comment || "No comment yet",
        date: lender?.date ?? null,
        frequency: lender?.frequency ?? "-",
        fundingAmount: lender?.funding_amount ?? "-",
        paybackAmount: lender?.payback_amount ?? "-",
        noOfPayments: lender?.no_of_payments ?? "-",
        payment: lender?.payment ?? "-",
        factorRate: lender?.factor_rate ?? "-",
      })
    })
    setOldLenderTableData(filteredData)
    
  }, [oldLender])
  
  const columns = [
    {
      title: "Lender",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div className={`flex items-center space-x-2 px-2 py-1  ${(status === 'Declined' || status === 'DECLINED') ? 'bg-declined-bgcolor' : (status === "Approved" || status === "APPROVED") ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
          <span className={`h-2 w-2 ${(status === 'Declined' || status === 'DECLINED') ? 'bg-red-600' : (status === "Approved" || status === "APPROVED") ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
          <span className={` ${(status === 'Declined' || status === 'DECLINED') ? 'text-red-600' : (status === "Approved" || status === "APPROVED") ? "text-approved-text" : "text-pendingText"} font-semibold`}>{status}</span>
        </div>
      ),
    },
    {
      title:"Comment",
      dataIndex:"comment",
      key:"comment",
      render:(comment)=>(
        <span className='w-[150px] block'>{comment}</span>
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <span>{date ? moment(date).format("MM/DD/YYYY") : "-"}</span>,
      sorter: (a, b) => moment(a?.date).unix() - moment(b?.date).unix()
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      sorter: (a, b) => a?.frequency?.localeCompare(b?.frequency)
    },
    {
      title: "Funding Amount",
      dataIndex: "fundingAmount",
      key: "fundingAmount",
      render: (fundingAmount) => {
        const numericValue = parseFloat(fundingAmount); // Convert to a number
        const formattedValue = !isNaN(numericValue)
          ? new Intl.NumberFormat('en-US').format(numericValue)
          : "-";
        return <span>{!isNaN(numericValue) ? `$${formattedValue}` : formattedValue}</span>;
      }
    },    
    {
      title: "Payback Amount",
      dataIndex: "paybackAmount",
      key: "paybackAmount",
      render: (paybackAmount) => {
        const numericValue = parseFloat(paybackAmount); // Convert to a number
        const formattedValue = !isNaN(numericValue)
          ? new Intl.NumberFormat('en-US').format(numericValue)
          : "-";
        return <span>{!isNaN(numericValue) ? `$${formattedValue}` : formattedValue}</span>;
      }
    }
,    
{
  title: "Payment",
  dataIndex: "payment",
  key: "payment",
  render: (payment) => {
    const numericValue = parseFloat(payment); // Convert to a number
    const formattedValue = !isNaN(numericValue)
      ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numericValue)
      : "-";
    return <span>{!isNaN(numericValue) ? `$${formattedValue}` : formattedValue}</span>;
  }
}

,
    {
      title: "Factor Rate",
      dataIndex: "factorRate",
      key: "factorRate",
    },
  ];

  return (
    <div className="flex-grow overflow-y-scroll mb-5">
      <div className='mt-4 w-full'>
        <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
          <Typography
            className='text-lg font-semibold text-good-morning-text'
          >
            Lenders
          </Typography>
        </div>
        {/* <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
          <img
            src={searchIcon}
            alt="Search Icon"
            className="w-8 h-8 mr-2"
          />
          <input
            type="text"
            placeholder="Search information"
            className="bg-background-color focus:outline-none pl-3 w-full"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div> */}
        <Table
          scroll={{
            x: 800,
          }}
          loading={oldLenderLoading}
          columns={columns}
          dataSource={oldLenderTableData} />
      </div>
    </div>
  )
}

export default OldDealLenderTable