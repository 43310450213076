import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import backgroundImage from '../data/Group 557.png';
import logo from '../data/Group 558.png';
import AuthTab from '../common/authTab/authTab';
import googleImg from '../data/google.png';
import GreenDotWithText from '../common/components/loginInstruction';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import { BiSolidToggleLeft, BiSolidToggleRight } from "react-icons/bi";
import { SET_ROLE } from '../redux/types/authTypes'; // Ensure the correct path to the action file
import { createPasswordNewrol, userIdAtom, userName } from '../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';

const CreatePassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState('Sign up');
  const { role } = useSelector((state) => state.auth);
  const [cPass, setPass] = useState('');
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setConfirmShowPassword] = useState(true);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [name, setName] = useRecoilState(userName);

  useEffect(() => {
    console.log("User Id ---------------  " , user_id)
  } , [])

  const handleSelect = (label) => {
    setSelected(label);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const toggleButton = () => {
    setToggleBtn(!toggleBtn);
  };

  const handleEmailChange = (e) => {
    setPass(e.target.value);
    if (e.target.value.includes('admin')) {
      dispatch({ type: SET_ROLE, payload: 'admin' });
    } else if (e.target.value.includes('agent')) {
      dispatch({ type: SET_ROLE, payload: 'agent' });
    } else if (e.target.value.includes('operations')) {
      dispatch({ type: SET_ROLE, payload: 'operations' });
    } else {
      dispatch({ type: SET_ROLE, payload: 'agent' });
    }
  };

  const handleSubmit = async (e) => {
    console.log("Submit Clicked ")
    e.preventDefault();
    if (cPass && password ) {
      if(cPass != password){
        alert("Password and Confirm Password should be the same!")
      }else {
      setIsLoading(true);
      
      const data =  await createPasswordNewrol( password, user_id);
      if(data.status == 1){
        setName(data.firstName)
        setuserId(data.userid)
        dispatch({ type: SET_ROLE, payload: data.role });
        navigate(role === 'admin' ? '/business-analytics' : '/dashboard');
        setIsLoading(false)
      }else{
        alert(data.message)
        setIsLoading(false)
      }

      }
    } else {
      alert('Please fill in both email and password fields.');
    }
  };

  return (
    <div className="flex h-screen bg-background-color">
      <div className="w-2/5 flex items-center justify-between" style={{ backgroundColor: '#00A762' }}>
        <img 
          src={backgroundImage} 
          alt="Background" 
          className="object-cover h-full w-auto"
        />
       
      </div>

      <div className="w-1/2 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-background-color rounded-lg align-middle">
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="w-auto h-auto" />
          </div>
            <div>
              <h1 className="text-2xl font-bold text-center text-custom-green text-4xl">Create password</h1>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Enter Password</label>
                  <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <IoLockClosedOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      ) : (
                        <IoLockOpenOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      )}
                    </span>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'password' : 'text'}
                      autoComplete="current-password"
                      placeholder="************"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                    />
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <AiOutlineEyeInvisible style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      ) : (
                        <AiOutlineEye style={{color:"#0A2759"}} onClick={togglePasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                    <span className="ml-1 bg-white px-3 py-2">
                      {showPassword ? (
                        <IoLockClosedOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      ) : (
                        <IoLockOpenOutline style={{color:"#0A2759"}} className="inline-block text-xl font-m" />
                      )}
                    </span>
                    <input
                      id="password"
                      name="password"
                      type={showConfirmPassword ? 'password' : 'text'}
                      autoComplete="current-password"
                      placeholder="************"
                      value={cPass}
                      onChange={(e) => setPass(e.target.value)}
                      required
                      className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                    />
                    <span className="ml-1 bg-white px-3 py-2">
                      {showConfirmPassword ? (
                        <AiOutlineEyeInvisible style={{color:"#0A2759"}} onClick={toggleConfirmPasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      ) : (
                        <AiOutlineEye style={{color:"#0A2759"}} onClick={toggleConfirmPasswordVisibility} className="cursor-pointer inline-block font-m text-xl" />
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <div className='flex'>
                      <GreenDotWithText text="At least one letter" />
                      <GreenDotWithText text="At least 8 characters" />
                    </div>
                    <GreenDotWithText text="At least one number" />
                  </div>
                  <div className='flex items-center gap-4'>
                    <div>
                      {toggleBtn ? (
                        <BiSolidToggleRight onClick={toggleButton} className='text-custom-green text-4xl cursor-pointer' /> 
                      ) : (
                        <BiSolidToggleLeft onClick={toggleButton} className='text-slate-300 text-4xl cursor-pointer' />
                      )}
                    </div>
                    <div className={`${toggleBtn ? 'text-custom-green font-normal text-m' : 'text-slate-500 font-normal text-m'}`}>
                      I agree to the <span className='font-medium'>Terms & Conditions</span>
                    </div>
                  </div>
                  {/* <div> */}
                  <button
                  onClick={() => {}}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4"
                    style={{ backgroundColor: '#00A762' }}
                  >
                    Continue
                  </button>
                  {/* </div> */}
                 
                </div>
               
               
              </form>
            </div>
          
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
