import { CREATE_NEW_TASK_FAIL, CREATE_NEW_TASK_REQUEST, CREATE_NEW_TASK_SUCCESS, GET_ADMIN_CARD_SB_DATA_FAIL, GET_ADMIN_CARD_SB_DATA_REQUEST, GET_ADMIN_CARD_SB_DATA_SUCCESS, GET_ALL_TASKS_FAIL, GET_ALL_TASKS_REQUEST, GET_ALL_TASKS_SUCCESS, GET_BUSINESS_ANALYTICS_DATA_FAIL, GET_BUSINESS_ANALYTICS_DATA_REQUEST, GET_BUSINESS_ANALYTICS_DATA_SUCCESS, GET_CHART_DATA_FAIL, GET_CHART_DATA_REQUEST, GET_CHART_DATA_SUCCESS, GET_COMPANIES_FAIL, GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_ADMIN_SUBMISSION_DATA_FAIL, GET_ADMIN_SUBMISSION_DATA_REQUEST, GET_ADMIN_SUBMISSION_DATA_SUCCESS, SET_FUNDING_TARGET_FAIL, SET_FUNDING_TARGET_REQUEST, SET_FUNDING_TARGET_SUCCESS, } from '../types/dashboardTypes';

export const getAllTasksReducer = (state = {
  loading: false,
  allTasks: []
}, action) => {
  switch (action.type) {
    case GET_ALL_TASKS_REQUEST:
      return { loading: true };
    case GET_ALL_TASKS_SUCCESS:
      return { loading: false, allTasks: action.payload };
    case GET_ALL_TASKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const createNewTaskReducer = (state = {
  loading: false,
  createTask: {}
}, action) => {
  switch (action.type) {
    case CREATE_NEW_TASK_REQUEST:
      return { loading: true };
    case CREATE_NEW_TASK_SUCCESS:
      return { loading: false, createTask: action.payload };
    case CREATE_NEW_TASK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const fetchCompaniesReducer = (state = {
  loading: false,
  companies: [],
}, action) => {
  switch (action.type) {
    case GET_COMPANIES_REQUEST:
      return { ...state, loading: true };
    case GET_COMPANIES_SUCCESS:
      return { loading: false, companies: action.payload };
    case GET_COMPANIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fetchChartDataReducer = (state = {
  loading: false,
  chartData: [],
}, action) => {
  switch (action.type) {
    case GET_CHART_DATA_REQUEST:
      return { ...state, loading: true };
    case GET_CHART_DATA_SUCCESS:
      return { loading: false, chartData: action.payload };
    case GET_CHART_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const setFundingTargetReducer = (state = {
  loading: false,
  fundingTarget: {}
}, action) => {
  switch (action.type) {
    case SET_FUNDING_TARGET_REQUEST:
      return { loading: true };
    case SET_FUNDING_TARGET_SUCCESS:
      return { loading: false, fundingTarget: action.payload };
    case SET_FUNDING_TARGET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getBusinessAnalyticsDataReducer = (state = {
  loading: false,
  businessAnalyticsData: {}
}, action) => {
  switch (action.type) {
    case GET_BUSINESS_ANALYTICS_DATA_REQUEST:
      return { loading: true };
    case GET_BUSINESS_ANALYTICS_DATA_SUCCESS:
      return { loading: false, businessAnalyticsData: action.payload };
    case GET_BUSINESS_ANALYTICS_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getAdminSubmissionDataReducer = (state = {
  loading: false,
  submissionData: {}
}, action) => {
  switch (action.type) {
    case GET_ADMIN_SUBMISSION_DATA_REQUEST:
      return { loading: true };
    case GET_ADMIN_SUBMISSION_DATA_SUCCESS:
      return { loading: false, submissionData: action.payload };
    case GET_ADMIN_SUBMISSION_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}