import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../data/Group 557.png';
import logo from '../data/Group 558.png';
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { resetPassword, sendOTP } from '../atom/authuserData/userdata';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isLoading  , setIsloading] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleSendOtp = () => {
    setIsOtpSent(true);
    setTimer(30); // Start a 30-second timer
    // Add OTP sending logic here (e.g., API call)
    console.log('OTP Sent');
    handleSendOTP()
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    // Add reset password logic here
    alert('Password reset successfully!');
    navigate('/');
  };

  const handleSendOTP = async () => {
    if (email) {
      setIsloading(true);
      try {
        const data = await sendOTP(email);
        if (data.status === 1) {
          console.log("Data after login", data);
        } else {
          setIsloading(false);
        }
        console.log("data ----------------- ", data);
        alert(data.message)
        setIsloading(false);
      } catch (error) {
        console.error("Error sending OTP", error);
        setIsloading(false);
      }
    } else {
      alert('Please fill in the email field.');
    }
  };

  const handleResetButton = async () => {
    if (password !== confirmPassword) {
        alert('Passwords do not match!');
        return;
    }
    if (email , otp , password) {
      setIsloading(true);
      try {
        const data = await resetPassword(email , otp , password);
        if (data.status === 1) {
          console.log("Data after login", data);
          alert(data?.message);
          navigate('/', { replace: true });
        } else {
          setIsloading(false);
        }
        console.log("data ----------------- ", data);
      } catch (error) {
        console.error("Error sending OTP", error);
        setIsloading(false);
      }
    } else {
      alert('Please fill in the email field.');
    }
  };
  

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div className="flex h-screen bg-background-color">
      <div className="w-2/5 flex items-center justify-between" style={{ backgroundColor: '#00A762' }}>
        <img
          src={backgroundImage}
          alt="Background"
          className="object-cover h-full w-auto"
        />
      </div>

      <div className="w-1/2 flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-3 bg-background-color rounded-lg align-middle">
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="w-auto h-auto" />
          </div>
          <h1 className="text-2xl font-bold text-center text-custom-green text-4xl">Reset Password</h1>
          <form className="mt-8 space-y-6"  onSubmit={(e) => {
    e.preventDefault(); // Prevent form reload
    handleResetButton(); // Call the reset function
  }}>
            {/* Email Field */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="olivia@fidifunding.com"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* OTP Field */}
            <div>
              <div className="flex justify-between items-center">
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">OTP</label>
                <button
                  type="button"
                  onClick={handleSendOtp}
                  disabled={isOtpSent && timer > 0}
                  className={`text-sm font-bold ${
                    isOtpSent && timer > 0 ? 'text-gray-500' : 'text-custom-green'
                  }`}
                >
                  {isOtpSent && timer > 0 ? `Resend OTP in ${timer}s` : 'Send OTP'}
                </button>
              </div>
              <input
                id="otp"
                name="otp"
                type="text"
                placeholder="Enter 6-digit OTP"
                maxLength={6}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Password Field */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                <span className="ml-1 bg-white px-3 py-2">
                  {showPassword ? (
                    <IoLockClosedOutline style={{ color: "#0A2759" }} className="inline-block text-xl font-m" />
                  ) : (
                    <IoLockOpenOutline style={{ color: "#0A2759" }} className="inline-block text-xl font-m" />
                  )}
                </span>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'password' : 'text'}
                  placeholder="************"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                />
                <span className="ml-1 bg-white px-3 py-2">
                  {showPassword ? (
                    <AiOutlineEyeInvisible
                      style={{ color: "#0A2759" }}
                      onClick={togglePasswordVisibility}
                      className="cursor-pointer inline-block font-m text-xl"
                    />
                  ) : (
                    <AiOutlineEye
                      style={{ color: "#0A2759" }}
                      onClick={togglePasswordVisibility}
                      className="cursor-pointer inline-block font-m text-xl"
                    />
                  )}
                </span>
              </div>
            </div>

            {/* Confirm Password Field */}
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <div className="flex items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white">
                <span className="ml-1 bg-white px-3 py-2">
                  {showConfirmPassword ? (
                    <IoLockClosedOutline style={{ color: "#0A2759" }} className="inline-block text-xl font-m" />
                  ) : (
                    <IoLockOpenOutline style={{ color: "#0A2759" }} className="inline-block text-xl font-m" />
                  )}
                </span>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'password' : 'text'}
                  placeholder="************"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 sm:text-sm outline-none"
                />
                <span className="ml-1 bg-white px-3 py-2">
                  {showConfirmPassword ? (
                    <AiOutlineEyeInvisible
                      style={{ color: "#0A2759" }}
                      onClick={toggleConfirmPasswordVisibility}
                      className="cursor-pointer inline-block font-m text-xl"
                    />
                  ) : (
                    <AiOutlineEye
                      style={{ color: "#0A2759" }}
                      onClick={toggleConfirmPasswordVisibility}
                      className="cursor-pointer inline-block font-m text-xl"
                    />
                  )}
                </span>
              </div>
            </div>

            <div>
              <button
            //   onClick={handleResetButton}
                disabled={isLoading}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-8"
                style={{ backgroundColor: '#00A762' }}
              >
                 { isLoading ?'Loading...' :'Reset Password'} 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
