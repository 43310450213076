import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, Button, Row, Col } from "antd";

const schema = yup.object({
  date: yup.string().required("Date is required"),
  companyName: yup.string().required("Company name is required"),
  fundedAmount: yup.number().required("Funded amount is required"),
  upsellPercentage: yup.number().required("Upsell percentage is required"),
  lender: yup.string().required("Lender is required"),
  commission: yup.number().required("Commission is required"),
  aup: yup.number().required("AUP percentage is required"),
  split: yup.number().required("Split is required"),
  remaining: yup.number().required("Remaining is required"),
  agent: yup.string().required("Agent is required"),
}).required();

const EditCommissionFormPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rowData = location.state?.commissionData || {};

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: rowData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("Updated Data:", data);
    // Perform API call or state update here
    navigate("/commission-list"); // Navigate back to the commission list
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <label>Date</label>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter Date"
                status={errors.date ? "error" : ""}
              />
            )}
          />
          {errors.date && <p className="text-red-500">{errors.date.message}</p>}
        </Col>
        <Col xs={24} md={12}>
          <label>Company Name</label>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter Company Name"
                status={errors.companyName ? "error" : ""}
              />
            )}
          />
          {errors.companyName && (
            <p className="text-red-500">{errors.companyName.message}</p>
          )}
        </Col>
        <Col xs={24} md={12}>
          <label>Funded Amount</label>
          <Controller
            name="fundedAmount"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter Funded Amount"
                status={errors.fundedAmount ? "error" : ""}
              />
            )}
          />
          {errors.fundedAmount && (
            <p className="text-red-500">{errors.fundedAmount.message}</p>
          )}
        </Col>
        {/* Add fields for other properties like upsellPercentage, lender, etc. */}
        <Col xs={24}>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default EditCommissionFormPage;
