// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import ReusableTable from "../../../common/tables/Table";
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../data/Group 536.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmissionsAction, setShownOldDealsButton } from '../../../redux/actions/submissionBoardAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { Button, Select, Spin } from 'antd';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { FaCircle } from 'react-icons/fa';

const ExistingLeadModal = () => {
  const [submissionsTableData, setSubmissionsTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showOldDeals, setShowOldDeals] = useState(false);
  const [status, setStatus] = useState('');
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: submissionsLoading, submissions } = useSelector(state => state.getAllSubmissions);
  const { showOldDeals: showOldDealsButton } = useSelector(state => state.setShownOldDealsButton);

  useEffect(() => {
    dispatch(getAllSubmissionsAction(userId));
  }, []);

  useEffect(() => {
    setShowOldDeals(showOldDealsButton);
  }, [showOldDealsButton]);

  

  useEffect(() => {
    let filteredData = []
    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = submissions.filter((submission) => {
        const agentName = `${submission?.firstName} ${submission?.lastName}`;
        return (
          submission?.agent_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.company_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.tag.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.lead_source.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          agentName.toLowerCase().includes(trimmedSearchText.toLowerCase())
        )
      });
    } else {
      filteredData = submissions;
    }

    const submissionData = []
    if (filteredData?.length > 0) {
      filteredData.forEach((submission) => {
        submissionData.push({
          key: submission?._id,
          status: submission?.status,
          agent: submission?.agent_name,
          company: submission?.company_name,
          date: submission?.createdAt,
          leadId: submission?.tag,
          firstName: submission?.firstName, // Include firstName
        lastName: submission?.lastName, 
        city: submission?.city,
        street: submission?.street,
        email: submission?.email,
        phone: submission?.phone,
        leadTag: submission?.tag,
          leadSource: submission?.lead_source,
          lenders: 'Update Lenders',
          openDeal: submission,
          createdAt: submission?.createdAt,
        });
      });
    }

    setSubmissionsTableData(submissionData?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [submissions, searchText]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
            <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
            <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Merchant name',
        accessor: 'company',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => (
          <span>{moment(value).format("MM/DD/YYYY")}</span>
        ),
      },
      {
        Header: 'Lead ID',
        accessor: 'leadId',
      },
      {
        Header: 'Lead Source',
        accessor: 'leadSource',
      },
      {
        Header: 'Comments',
        accessor: 'comment',
        Cell: ({ value }) => <span>{value || 'No Comments yet'}</span>,
      },

      {
        Header: 'Create Lead',
        accessor: 'createLead',
        Cell: ({ row }) => <Button onClick={() =>
        {
          console.log("Row Values -- " , row.original)
            handleNavigateToAddLead(row.original)
        }
        } alt="Open Deal" style={{ cursor: 'pointer', backgroundColor: 'green' , color: 'white' }} >Create Lead</Button>, // Render the eye icon
      },
    ],
    []
  );
  const handleStatusChange = (value) => {
    setStatus(value);
  }

  const handleNavigateToAddLead = (leadData) => {
    navigate("/add-lead", { state: { leadData } });
  };

  return (
    <div>
      <main className="flex-1 bg-background-color p-6  flex flex-col">
          <div className="flex-grow overflow-y-auto mx-4 mb-5">
            {submissionsLoading ?
              <div className='w-full pt-16 flex justify-center items-center' >
                <Spin />
              </div>
              :
              <div className='mt-4'>
                <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                  <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                      className="w-8 h-8 mr-2"
                    />
                    <input
                      type="text"
                      placeholder="Search information"
                      className="bg-background-color focus:outline-none pl-3 w-full"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <ReusableTable columns={columns} data={submissionsTableData} />
              </div>
            }
          </div>
        
      </main>
    </div>
  );
};

export default ExistingLeadModal;
