import { useState } from 'react';

export const HorizontalFilter = ({ options, onStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = useState('All');

  const handleFilterClick = (value) => {
    setSelectedStatus(value); // Update the active status
    onStatusChange(value); // Trigger the sorting/filtering logic
  };

  return (
    <div className="flex justify-between items-center border-b border-gray-300">
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => handleFilterClick(option.value)}
          className={`flex flex-col items-center justify-center flex-1 text-center py-3 text-sm font-medium transition-colors duration-200 ${
            selectedStatus === option.value
              ? 'text-green-600 border-b-4 border-green-500'
              : 'text-gray-600 hover:text-gray-800'
          }`}
          style={{
            borderBottomWidth: selectedStatus === option.value ? '4px' : '0',
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};