import React, { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Pagination from '../components/Pagination/Pagination';

// let PageSize = 10;

const ReusableTable = ({ columns, data, maxRow }) => {
  const [PageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data  , PageSize]);

  useEffect(() => {
    if (maxRow) {
      setPageSize(maxRow)
    } else {
      setPageSize(10)
    }
  }, [maxRow])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: currentTableData,
      initialState: { pageIndex: 0 },
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()} className='w-full text-gray-500 '>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ borderBottom: 'solid 1px #D0D3D9', background: '', color: '#667085', fontWeight: '500', paddingBlock: '13px', fontSize: '14px' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ backgroundColor: 'white', paddingBlock: '25px' }}>

        {rows.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length}
                style={{
                  textAlign: 'center',
                  padding: '20px',
                  fontSize: '16px',
                  color: '#999',
                }}
              >
                No records found
              </td>
            </tr>
          ) :

          (rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ borderBottom: 'solid 1px #D0D3D9', paddingBlock: '24px', paddingLeft: '40px' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          }))}
        </tbody>
      </table>
      <div className='flex mt-4 justify-between w-full'>
        <button
          className='flex items-center border border-gray-400 p-2 rounded rounded-lg cursor-pointer'
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <FaArrowLeft className='mr-2' />
          Previous
        </button>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
        <button
          className='flex items-center border border-gray-400 p-2 rounded rounded-lg cursor-pointer mr-5 pl-5'
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / PageSize)))}
          disabled={currentPage === Math.ceil(data.length / PageSize)}
        >
          Next
          <FaArrowRight className='ml-2' />
        </button>
      </div>
    </>
  );
};

export default ReusableTable;
