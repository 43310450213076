export const GET_ALL_LEADS_REQUEST = "GET_ALL_LEADS_REQUEST";
export const GET_ALL_LEADS_SUCCESS = "GET_ALL_LEADS_SUCCESS";
export const GET_ALL_LEADS_FAIL = "GET_ALL_LEADS_FAIL";


export const ADD_LEAD_REQUEST = "ADD_LEAD_REQUEST";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const ADD_LEAD_FAIL = "ADD_LEAD_FAIL";

export const UPDATE_LEAD_REQUEST = "UPDATE_LEAD_REQUEST";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL";

export const GET_ACTIVITY_LOGS_REQUEST = "GET_ACTIVITY_LOGS_REQUEST";
export const GET_ACTIVITY_LOGS_SUCCESS = "GET_ACTIVITY_LOGS_SUCCESS";
export const GET_ACTIVITY_LOGS_FAIL = "GET_ACTIVITY_LOGS_FAIL";

export const SEND_LEAD_TO_OPERATIONS_REQUEST = "SEND_LEAD_TO_OPERATIONS_REQUEST";
export const SEND_LEAD_TO_OPERATIONS_SUCCESS = "SEND_LEAD_TO_OPERATIONS_SUCCESS";
export const SEND_LEAD_TO_OPERATIONS_FAIL = "SEND_LEAD_TO_OPERATIONS_FAIL";

export const POST_CSV_FILE_REQUEST = "POST_CSV_FILE_REQUEST";
export const POST_CSV_FILE_SUCCESS = "POST_CSV_FILE_SUCCESS";
export const POST_CSV_FILE_FAIL = "POST_CSV_FILE_FAIL";

export const POST_FILE_REQUEST = "POST_FILE_REQUEST";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_FAIL = "POST_FILE_FAIL";

export const GET_ALL_FILES_REQUEST = "GET_ALL_FILES_REQUEST";
export const GET_ALL_FILES_SUCCESS = "GET_ALL_FILES_SUCCESS";
export const GET_ALL_FILES_FAIL = "GET_ALL_FILES_FAIL";
export const RESET_ALL_FILES = "RESET_ALL_FILES";

export const GET_DELETE_LEADS_FAIL = "GET_DELETE_LEADS_FAIL";
export const GET_DELETE_LEADS_REQUEST = "GET_DELETE_LEADS_REQUEST";
export const GET_DELETE_LEADS_SUCCESS = "GET_DELETE_LEADS_SUCCESS";

