import axios from "axios"
import APIUrl from '../atom/APIUrl'

const instance = axios.create({
  timeout: 30000,
  baseURL: APIUrl,
  headers: {
    "Content-Type": "application/json",
  },
})

// Make a request for a common post and get records
export const axiosGet = (url, config) => instance.get(url, config)
export const axiosPost = (url, payload, config) => instance.post(url, payload, config)
export const axiosDelete = (url, config) => instance.delete(url, config)