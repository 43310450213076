import { axiosGet, axiosPost } from '../axios';
import { DELETE_LEAD_FAIL, DELETE_LEAD_REQUEST, DELETE_LEAD_SUCCESS, GET_QUEUED_LEADS_FAIL, GET_QUEUED_LEADS_REQUEST, GET_QUEUED_LEADS_SUCCESS, GET_REMOVED_LEADS_FAIL, GET_REMOVED_LEADS_REQUEST, GET_REMOVED_LEADS_SUCCESS, POST_COMPLIANCE_FORM_FAIL, POST_COMPLIANCE_FORM_REQUEST, POST_COMPLIANCE_FORM_SUCCESS, RESTORE_LEAD_FAIL, RESTORE_LEAD_REQUEST, RESTORE_LEAD_SUCCESS } from '../types/complianceTypes';

export const postComplianceFormAction = (userId, complianceData) => async (dispatch) => {
  try {
    dispatch({ type: POST_COMPLIANCE_FORM_REQUEST });

    const { data } = await axiosPost(`compliance/addrequest?user_id=${userId}`, complianceData);
    dispatch({ type: POST_COMPLIANCE_FORM_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_COMPLIANCE_FORM_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
    return error.response.data;
  }
}

export const getQueuedLeadsAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_QUEUED_LEADS_REQUEST });

    const { data } = await axiosGet(`compliance/getRequests?user_id=${userId}`);
    dispatch({ type: GET_QUEUED_LEADS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_QUEUED_LEADS_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const getRemovedLeadsAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_REMOVED_LEADS_REQUEST });

    const { data } = await axiosGet(`compliance/getRemovedLeads?user_id=${userId}`);
    dispatch({ type: GET_REMOVED_LEADS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_REMOVED_LEADS_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const restoreLeadAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: RESTORE_LEAD_REQUEST });

    const { data } = await axiosGet(`compliance/restoreLeads?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: RESTORE_LEAD_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: RESTORE_LEAD_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const deleteLeadAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_LEAD_REQUEST });

    const { data } = await axiosGet(`compliance/deleteLead?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: DELETE_LEAD_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: DELETE_LEAD_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

