import { GET_ALL_REPORT_FAIL, GET_ALL_REPORT_REQUEST, GET_ALL_REPORT_SUCCESS } from "../types/reportTypes";


export const getAllReportReducer = (state = {
    loading: true,
    allReports: [],
    data:0
  }, action) => {
    switch (action.type) {
      case GET_ALL_REPORT_REQUEST:
        return { loading: true };
      case GET_ALL_REPORT_SUCCESS:
        return { loading: false, allReports: action.payload , data :action.data};
      case GET_ALL_REPORT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }