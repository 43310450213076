import { Button, Col, Input, Row } from 'antd'
import DashboardTab from '../../../common/components/DashboardTab/DashboardTabs'
import GoodMorningBar from '../../../common/components/GoodmorningBar'
import * as yup from "yup"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addLeaderAction } from '../../../redux/actions/leaderAction'
import { useRecoilState } from 'recoil'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(100)
      .required("Enter first name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter Last Name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    phoneNumber: yup.string().required("Mobile Number Must Be 10 Digits"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    split: yup.string().required("Split is required"),
    leaderPercentage: yup.string().required("Leader Percentage is required"),
  })
  .required()

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  split: "",
  leaderPercentage: "",
}

const AddLeader = () => {
  const { userId } = useSelector((state) => state.auth);
  const { loading: addLeaderLoading } = useSelector(state => state.addLeader);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  
  const onSubmit = async data => {
    const { firstName, lastName,  email, phoneNumber, split,  leaderPercentage } = data
    console.log("Data ::",data)
    const leaderData = {
      "firstName": firstName,
      "lastName": lastName,
      "phone": formatPhoneNumber(phoneNumber),
      "email": email,
      "split": split,
      "leadersPerc": leaderPercentage,
    }
    console.log("Leader data::",leaderData)
    const result = await dispatch(addLeaderAction(userId, leaderData))
    console.log("Leader result::",result)
    if (result?.status === 1) {
      toast.success("Leader added successfully")
      navigate("/add-new%20role")
    } else {
      toast.error(result?.message)
    }
  }
  
 
  const handleDraft = () => {
    toast.success("Saved as draft")
  }

  const formatPhoneNumber = (number) => {
    const cleaned = ('' + number).replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/); // Format as (XXX) XXX-XXXX
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number; // Return the number as-is if it doesn't match the expected pattern
  };

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col w-full">
        <GoodMorningBar userName="Manish" />
        <div
          className='w-[97%] my-6 bg-white p-6 rounded-lg m-5 h-full'
        >
          <h1 className='text-lg font-medium'>Leader Information</h1>
          <form className="pt-4 flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
            {/* <Spin spinning={true}> */}
            <Row gutter={16}>
              
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-firstName">
                  First Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-firstName"
                      placeholder='Enter First Name'
                      status={errors.firstName ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.firstName ? (
                  <small className="text-red-500 capitalize">{errors.firstName.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-lastName">
                  Last Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-lastName"
                      placeholder='Enter Last Name'
                      status={errors.lastName ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.lastName ? (
                  <small className="text-red-500 capitalize">{errors.lastName.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-email">
                  Email
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      type="email"
                      id="input-email"
                      placeholder='Enter Email'
                      status={errors.email ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.email ? (
                  <small className="text-red-500 capitalize">{errors.email.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-phoneNumber">
                  Phone Number
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input
                      size="large"
                      id="input-phoneNumber"
                      placeholder='Enter Phone Number'
                      value={formatPhoneNumber(field.value)} // Format the value before displaying
                      onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))} // Format on change
                      // {...field}
                      status={errors.phoneNumber ? "error" : undefined}
                    />}
                />
                {errors.phoneNumber ? (
                  <small className="text-red-500 capitalize">{errors.phoneNumber.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-split">
                  Split
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="split"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-split"
                      placeholder='Enter Split'
                      status={errors.split ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.split ? (
                  <small className="text-red-500 capitalize">{errors.split.message}</small>
                ) : null}
              </Col>

             
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-leaderPercentage">
                  Leader Percentage
                </label>
                <Controller
                  control={control}
                  name="leaderPercentage"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-leaderPercentage"
                      placeholder='Enter Leader Percentage '
                      status={errors.leaderPercentage ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.leaderPercentage ? (
                  <small className="text-red-500 capitalize">{errors.leaderPercentage.message}</small>
                ) : null}
              </Col>

            </Row>
            {/* </Spin> */}
            <div className='flex justify-end items-center gap-4 mb-5'>
             
              <Button
                htmlType="submit"
                loading={addLeaderLoading}
                className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
              >
                Submit Leader
              </Button>
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

export default AddLeader