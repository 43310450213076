import React, { useEffect } from 'react';
import './ChildNavigation.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setChildSelectedTab } from '../../../../redux/actions/authAction';

const ChildNavigation = ({ items, label }) => {
  const { childSelectedTab: currentSelected } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '').replace('-', ' ');
      if (hash) {
        const capitalizedHash = hash.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        dispatch(setChildSelectedTab(capitalizedHash));
      }
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [dispatch]);

  const handleSelect = (item) => {
    dispatch(setChildSelectedTab(item)); // Dispatch the action
    const formattedItem = item.toLowerCase().replace(' ', '-');
    navigate(`/${formattedItem}`);
  };

  return (
    <div className="Childmenuuu cursor-pointer pt-20 pl-1">
      <div className="text-white pl-3 font-semibold text-xl">
        {label}
      </div>
      {items.map((item, index) => {
        const isSelected = currentSelected === item;
        return (
          <a
            key={index}
            className={`Childmenuuu-item ${isSelected ? 'selected text-black bg-background-color' : ''}`}
            style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingInline: '30px' }}
            id={item.toLowerCase().replace(' ', '-')}
            onClick={() => handleSelect(item)}
          >
            <span style={{ fontSize: 13, marginLeft: '16px' }}>
              {item}
            </span>
          </a>
        );
      })}
    </div>
  );
};

export default ChildNavigation;
